import React, { Component } from 'react'
import "./Auth.css"


import AppContext from '../../main/AppContext' //./AppContext'
import axios from 'axios'
import cogoToast from "cogo-toast"
import {FaUserCheck} from "react-icons/fa"
import {API_URL} from "../../consts";



class Auth extends Component {
    constructor(props) {
        super(props)
                
        this.user = {}
        this.state = {nome: '',
                      email:'',
                      senha: '',
                      senha2: '',
                      confirmaSenha: "Confirme a senha",
                      showSignup: false,
                     };

        this.handleChangeNome = this.handleChangeNome.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeSenha = this.handleChangeSenha.bind(this);
        this.handleChangeSenha2 = this.handleChangeSenha2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
      }
    
      handleChangeNome(event) {
        this.setState({nome: event.target.value});
      }

      handleChangeEmail(event) {
        this.setState({email: event.target.value});
      }
      handleChangeSenha(event) {
        this.setState({senha: event.target.value});
      }
      handleChangeSenha2(event) {
         
        this.setState({senha2:event.target.value});
      }

      handleSubmit(event) {
        
        
       this.setState({showSignup: (!this.state.showSignup)});
    
        event.preventDefault()

      }
    
      
    render() {

        return (
       <AppContext.Consumer>
                        {context => (
                            <React.Fragment>
                               
            
        <div class="auth-content "> 
            <div class="auth-modal">
                {/* <img src="@/assets/logo.png" width="200" alt="Logo" /> */}
                                   
                    {(this.state.showSignup) ? <div class="auth-title fa fa-user-plus">
                                          <strong className="text-primary">Cadastro</strong>  </div>:
                                         <div className="auth-title"><FaUserCheck/>
                                         <strong className= "text-primary">Login</strong> </div>} 
                    
                    <hr class="text-primary"/>
                    
                        {(this.state.showSignup)?[<i className="_i"><aside class="fa fa-address-card-o"></aside>
                        <input type="text" value={this.state.nome} placeholder="Nome" onChange={this.handleChangeNome} /></i>]: 
                                        <span className="fa fa-gg text-primary "></span>}
                    <i className="_i"><aside class="fa fa-envelope-o"></aside><input  value={this.state.email} type ="email" placeholder="e-mail"  onChange={this.handleChangeEmail} /></i>
                    <i className="_i"><aside class="fa fa-key"></aside><input  value={this.state.senha} type="password" placeholder="Senha" onChange={this.handleChangeSenha} /></i>
                    {(this.state.showSignup)? [<i className="_i"><aside class="fa fa-key"></aside> <input value={this.state.senha2}
                                              type="password" placeholder= {this.state.confirmaSenha} onChange={this.handleChangeSenha2}/></i>]: 
                                          <span className="fa fa-gg text-primary " ></span>}

                    {(this.state.showSignup)?    
                     <button className="bt-cadastrar" onClick={() => {  
                         //testa senha e confirmação
                        if( this.state.senha2 === this.state.senha ){
       
                        this.setState({showSignup: (!this.state.showSignup)});
                        const baseURL = API_URL+'usuario/cadastrar'
                        console.log( "email=",this.state.email, this.state.senha, "nome=", this.state.nome)
                             ////
                             
                           ///// 
                           axios({
                            method: "POST",
                            url: baseURL,
                            data: {
                                "nome": this.state.nome,
                                "email" : this.state.email,
                                "senha": this.state.senha
                                 },
                          headers:
                           {
                            'Content-Type': 'application/json'
                          }
                           

                          }).then(resp => {
                            

                            console.log("Gravado com sucesso")
                            cogoToast.success("Gravado com sucesso")
                            //const respObj = parseJSON(resp)
                            console.log("resposta=",resp)
                            context.setUserOj({nome: "Logar"})
                            context.setToken("")
                            context.setTokenExpiraDt("")
                            this.setState({showSignup: false})
                            
                        }).catch((error) => {
                          // Error
                          if (error.response) {
                              // The request was made and the server responded with a status code
                              // that falls out of the range of 2xx
                               console.log(1,error.response.data.erros); // este array de erros respondidos pela api
                               cogoToast.error("ATENÇÂO: ....................."+error.response.data.erros)
                               console.log(2,error.response.status);
                               console.log(3,error.response.headers, error.response.erros);
                          } else if (error.request) {
                              // The request was made but no response was received
                              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                              // http.ClientRequest in node.js
                              console.log(error.request);
                              cogoToast.error("ATENÇÂO: erro no servidor \n "+error.request)
                          } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log('5 Error', error.message);
                              cogoToast.error("ATENÇÂO: "+error.message)
                          }
                          console.log(6,error.config);
                          this.setState({showSignup: true})
                      });
                                         

                     } else { 
                       this.setState({senha:"", senha2:"" , confirmaSenha: "#### Senha não confere ####"})
                       this.setState({showSignup: true})
                       cogoToast.error("ATENÇÂO: #### Senha não confere ####")
                         }
                 
                       }}/*fim onClic.bt-cadastrar*/
                      >Registrar</button>  :


                     <button className="bt-Entrar" onClick={() => { 
                                     //const axios = require('axios');
                          const baseURL = API_URL+'usuario/login'
        
                          console.log( "email=",this.state.email, this.state.senha)
                             ////
                             
                           ///// 
                           axios({
                            method: "POST",
                            url: baseURL,
                            data: {
                                "email" : this.state.email,
                                "senha": this.state.senha
                                 }
                           /*headers: cabecalho*/
                          }).then(resp => {
                            
                            //const respObj = parseJSON(resp)
                            console.log("resposta=",resp)
                            context.setUserOj(resp.data.usuario[0])
                            
                            context.setToken(resp.data.token)
                            console.log("token=",context.token)
                            
                            context.setTokenExpiraDt(resp.data.dExp)
                            cogoToast.success("Bem Vindo!!! =>"+resp.data.usuario[0].nome)
                                    // const list = this.getUpdatedList(resp.data.data)
                                    // this.setState({ user: initialState.user, list })
                                }).catch( (error)=> {
                                  if (error.response) {
                                    // The request was made and the server responded with a status code
                                    // that falls out of the range of 2xx
                                     console.log(1,error.response.data.erros); // este array de erros respondidos pela api
                                     cogoToast.error("ATENÇÂO:..................... LOGIN NEGADO ......................- o recurso solicitado requer autenticação do usuário. ..........401 Authentication Failed: Bad credentials"
                                     )
                                     console.log(2,error.response.status);
                                     console.log(3,error.response.headers);
                                } else if (error.request) {
                                    // The request was made but no response was received
                                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                    // http.ClientRequest in node.js
                                    console.log(error.request);
                                    cogoToast.error("ATENÇÂO: erro no servidor \n "+error.request)
                                } else {
                                    // Something happened in setting up the request that triggered an Error
                                    console.log('5 Error', error.message);
                                    cogoToast.error("ATENÇÂO: "+error.message)
                                }
                              console.log(6,error.config);
                          })
                                .then(console.log("xxxxxxxxxxxxxxxxxxx"))
                             
                          
                            }}
                           
                            
                             /////
                             
                             >Entrar</button>}
            
                             
                             
                                    <p className="log"
                                      onClick={( (event) => { this.handleSubmit(event)
                                       console.log(this.showSignup)
                                       })}>
                                 {(this.state.showSignup)? 
                                        <span v-if="showSignup">Já tem cadastro? Acesse o Login!</span> :
                                        <span v-else>Não tem cadastro? Registre-se aqui!</span>}
                                    </p>
            </div>
        </div>
        </React.Fragment>
         ) 
         
         }</AppContext.Consumer> )

        }
        }
export default Auth 