import React from "react"

import { CincoLinhasSorteadas } from "./CincoLinhasSorteadas";
import carregandoImg from "../../assets/imgs/loading-gif.gif";
import CountDownModel from "../templates/ModeloCountdown";

import { LOCALE } from '../../consts'
export const TelaExtracao = ({ random, delta, timeToRepete }) => {


    // let atualExtracao = random.serialNumber;
    const aux = random.completionTime; //alterei aqui

    if (!aux)
        return (
            <div>
                {" "}
                <img src={carregandoImg} width="100" height="80" class="img-fluid" alt={'Carregando...'} />
                Carregando{" "}
            </div>
        );
    let ds = aux
        ? random.completionTime.split(" ")
        : ["2000-01-01", "03:18:17.000"];
    let d = new Date(ds[0] + "T" + ds[1] + "+00:00");

    // /* CALCULO DO TEMPO RESTANTE PARA ALTERAR A EXTRAÇÃO

    let deltaFalta = delta >= 0 ? delta : 0;


    return (
        <div>
            {" "}
            Sorteio extração Nº{" "}
            <strong className="text-danger">{random.serialNumber} </strong>
            <br /> Realizado dia: <br /> {d.toLocaleDateString(LOCALE)} às:{" "}
            {d.toLocaleTimeString(LOCALE)}
            {/**/}
            {delta && <TimerExtracao timeToRepete={timeToRepete} deltaFalta={deltaFalta} />}
            <hr />
            {/**/}

            <CincoLinhasSorteadas nSorteado={random.data} />

        </div>
    )

}

const TimerExtracao = ({ deltaFalta, timeToRepete }) =>
    (<div>
        {" "}
        <p>
            {" "}
            Faltam <strong className="text-danger">
                {deltaFalta}{" "}
            </strong> segundos{" "}
        </p>
        <p id="countdown">
            <CountDownModel
                timeToRepete={timeToRepete}
                cor={deltaFalta < 16 ? "#ff0000" : "#6265f8"}
            />
        </p>
    </div>)

export default TelaExtracao




TelaExtracao.defaultProps = {}
