export const getMassaDeAposta = ()=>{

let i = 1
let aArray=[]

    
    
    
    
    
    aArray.push({index:((i).toString()),tipoEnum:"GRUPO_5",    numerosJogados:[i++],valorSubAposta:10.0});
    aArray.push({index:((i).toString()),tipoEnum:"GRUPO_5",    numerosJogados:[i++],valorSubAposta:10.0});
    aArray.push({index:((i).toString()),tipoEnum:"GRUPO_5",    numerosJogados:[i++],valorSubAposta:10.0});
    aArray.push({index:((i).toString()),tipoEnum:"GRUPO_5",    numerosJogados:[i++],valorSubAposta:10.0});
    aArray.push({index:((i).toString()),tipoEnum:"GRUPO_5",    numerosJogados:[i++],valorSubAposta:10.0});
    aArray.push({index:((i).toString()),tipoEnum:"GRUPO_5",    numerosJogados:[i++],valorSubAposta:10.0});
i=1
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",    numerosJogados:[1],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA",       numerosJogados:[75],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"MILHAR_5",     numerosJogados:[1101],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"GRUPO",        numerosJogados:[19],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"GRUPO_5",      numerosJogados:[19],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"MILHAR",       numerosJogados:[1675],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"CENTENA",      numerosJogados:[101],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"CENTENA_5",    numerosJogados:[675],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DUQUE",        numerosJogados:[1,2],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DUQUE_D",      numerosJogados:[10,20],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"TERNO",        numerosJogados:[1,2,3],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"TERNO_D",      numerosJogados:[10,20,30],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAIVEM", numerosJogados:[1,2],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAIVEM", numerosJogados:[2,3],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAIVEM", numerosJogados:[3,4],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAIVEM", numerosJogados:[4,5],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAIVEM", numerosJogados:[5,6],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAI",    numerosJogados:[1,2],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAI",    numerosJogados:[2,3],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAI",    numerosJogados:[3,4],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAI",    numerosJogados:[4,5],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"PASSE_VAI",    numerosJogados:[5,6],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[11],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[12],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13+i],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[13],valorSubAposta:10.0})
    aArray.push({index:((i++).toString()),tipoEnum:"DEZENA_5",     numerosJogados:[14],valorSubAposta:40.0})


    return aArray
}

/*

//aArray.forEach(e=>{console.log(e.numerosJogados)})

*/
