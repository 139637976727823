import React from "react";
import { Button } from "react-bootstrap";
import cogoToast from "cogo-toast";
import AppContext from "../../main/AppContext"; //./AppContext'
import {
  FaCoins,
  FaUserAltSlash,
  FaSignInAlt,
  FaCartPlus
} from "react-icons/fa";
import { MdFiberNew } from "react-icons/md";
import { GiCheckeredDiamond } from "react-icons/gi";
import axios from "axios";
import { API_URL } from "../../consts";
import "./CrudAposta.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// number.toLocaleString(pais, myObj)
const pais = "pt-BR"
const  myObj = {
    minimumFractionDigits:2
  }

export default class ApostaHome extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      /* lista: initialState.lista,
        user: initialState.user,
        subAposta: initialState.subAposta,
        aposta: initialState.aposta,
        extracaoApostaDtoJ: initialState.extracaoApostaDtoJ,
        ocultaHora : false,
        deltaRandom: 0,
        ehAlteracao:false, */
    };
  }

  handleCompraLocal = (setLocalSaldo, saldo) => {
    if (saldo <= 50) {
      cogoToast
        .loading(" Obrigado pela compra. Boa sorte ...", {
          hideAfter: 3,
          heading: " EFETUANDO A COMPRA DE LOCALCOINS..."
        })
        .then(() => setLocalSaldo(saldo + 1000));
    } else
      cogoToast.error(
        "Para adicionar créditos é necessário ter menos que $50, continue apostando... ",
        { hideAfter: 6, heading: " ERRO na compra de LOCALCOINS" }
      );
  };

  handleCompraCredito = (
    saldo,
    keyMoeda,
    userOj,
    setUserOj,
    token,
    ehCompra
  ) => {
    if (saldo <= 50) {
      cogoToast
        .loading(" Obrigado pela compra. Boa sorte ...", {
          hideAfter: 3,
          heading: ` EFETUANDO A COMPRA DE ${keyMoeda}...`
        })
        .then(() => {
          //AXIOS
          const queryUrl = ehCompra ? "?compra=true" : "";
          const objEvento = {
            usuarioId: userOj.id,
            moedaEnum: keyMoeda,
            dataEvento: "",
            eventoEnum: "COMPRA",
            autenticacao: "",
            usuTransferenciaId: null,
            valorEvento: 1000.0
          };

          const cJSon = JSON.stringify(objEvento);
          axios({
            method: "POST",
            url: API_URL + "conta/compra" + queryUrl,
            //Authorization:  `Bearer ${tokenAdmin}`,
            data: cJSon,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          })
            .then(resp => {
              console.log("Gravado com sucesso");
              cogoToast.success(
                `Saldo atual:$_${resp.data.data.saldoConta}_${keyMoeda}  `,
                { hideAfter: 6, heading: " COMPRADO com sucesso..." }
              );
              let user = { ...userOj };
              user.mapSaldo[keyMoeda] = resp.data.data.saldoConta;
              setUserOj(user);
              //const respObj = parseJSON(resp)
              console.log("resposta=", resp);
            })
            .catch(error => {
              // Error
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(1, error.response.data.erros); // este array de erros respondidos pela api
                cogoToast.error(
                  "ATENÇÂO: ....................." + error.response.data.erros
                );
                console.log(2, error.response.status);
                console.log(3, error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                cogoToast.error(
                  "ATENÇÂO: erro no servidor \n " + error.request
                );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("5 Error", error.message);
                cogoToast.error("ATENÇÂO: " + error.message);
              }
              console.log(6, error.config);
            });
          /// FIM AXIOS
        });
    } else
      cogoToast.error(
        "Para adicionar créditos é necessário ter menos que $50, continue apostando... ",
        { hideAfter: 6, heading: `ERRO na compra de ${keyMoeda}` }
      );
  };

  Saldos = context => {
    const obj = context.userOj.mapSaldo;
    let retorno = [];
    if (obj) {
      
      Object.keys(obj).forEach(key => {
        console.log(key); //column01...
        console.log(obj[key]); //Coluna 01...
        retorno.push(
          <p>
            <FaCoins /> {key} ... ${obj[key].toLocaleString(pais, myObj)}
            <OverlayTrigger
              key="premio"
              overlay={
                <Tooltip id={`tooltip`}>
                  Como prêmio <GiCheckeredDiamond /> tem $1000 a cada 2 dias.
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() =>
                  this.handleCompraCredito(
                    obj[key],
                    key,
                    context.userOj,
                    context.setUserOj,
                    context.token
                  )
                }
              >
                <GiCheckeredDiamond />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              key="compra"
              overlay={
                <Tooltip id={`tooltip`}>
                  Se seu crédito diário esgotar, poderá comprar mais. Obs;
                  v.Beta não há compra real
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() =>
                  this.handleCompraCredito(
                    obj[key],
                    key,
                    context.userOj,
                    context.setUserOj,
                    context.token,
                    true
                  )
                }
              >
                <FaCartPlus />
              </Button>
            </OverlayTrigger>
          </p>
        );
      });
    } else
      retorno.push(
        <p>
          <FaUserAltSlash /> Você não está logado ...{" "}
          <Button
            variant="link"
            onClick={() => cogoToast.success("IR P LOGIN")}
          >
            {" "}
            <FaSignInAlt />
          </Button>{" "}
        </p>
      );
    return retorno;
  };

  render() {
    return (
      <AppContext.Consumer>
        {context => (
          <div>
            <h4> Saldos: </h4>
            <h5>Moedas p/ Jogos On-Line</h5>
            {this.Saldos(context)}
            <h5>Moedas p/ Jogar Localmente</h5>
            <p>
              {" "}
              <FaCoins /> LOCALCOIN .... $
              {context.localSaldo.toLocaleString(pais, myObj)}
              <OverlayTrigger
                key="premio"
                overlay={
                  <Tooltip id={`tooltip`}>
                    Como prêmio <GiCheckeredDiamond /> tem $1000 a cada 2 dias.
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  onClick={() =>
                    this.handleCompraLocal(
                      context.setLocalSaldo,
                      context.localSaldo
                    )
                  }
                >
                  <GiCheckeredDiamond />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                key="reset"
                overlay={
                  <Tooltip id={`tooltip`}>
                    Aqui dá um RESET e o jogo recomeça com $1000.
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  onClick={() =>
                    cogoToast
                      .success("JOGO RESTAURADO")
                      .then(_ => context.setLocalSaldo(1000))
                  }
                >
                  <MdFiberNew />
                </Button>
              </OverlayTrigger>
            </p>
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}


//render(<FormExample />);