import React, { Component } from 'react'
import axios from 'axios'
import Main from '../templates/Main'
import AppContext from '../../main/AppContext'
import { API_URL } from '../../consts'
import { FaChevronDown} from "react-icons/fa";
import { Button } from "react-bootstrap";
const headerProps = {
    icon: 'users',
    title: 'Usuários',
    subtitle: 'Conta de usuários: Alterar senha/email (não implementado), e Listar o Conta-Corrente!'
}

const baseUrl = API_URL + 'conta'

const initialState = {

    user: { nome: '', email: '', senha: "", id: "", token: "" },
    list: []
}


/////////////////////////////
const W_STORAGE_TOKEN_KEY = "_LB_TOKEN"
var localStorage = window.localStorage

////////////////////////


export default class UserCrud extends Component {

    state = {
        user: initialState,

        list: [],
        token: localStorage.getItem(W_STORAGE_TOKEN_KEY),
        tokenExpiraDt: ""
    }

    method = "get"
    takeContaCorrente = user => {
        this.setState({ user })
        //const axios = require('axios');

        axios.defaults.baseURL = baseUrl + '/lista'
        const token = this.state.token
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        axios.get()

            .then(resp => {
                console.log(resp)
                this.setState({ list: resp.data.data.content })

            }).catch(function (error) {
                console.log(error);
            });
    }
    //@TODO  gravar usuario novo pelo administrador a utenticaçao está errada
    save() {
        const user = this.state.user
        console.log("usuario", JSON.stringify(user))
        const method = user.id ? 'put' : 'post'
        const url = user.id ? `${baseUrl}/${user.id}` : baseUrl
        //axios.defaults.headers.post['Content-Type'] = 'application/json';
        //const cabecalho ="{ 'content-type': 'application/json'}"
        axios({
            method: method,
            url: url,
            data: user,
            auth: {
                username: 'admin@jogodobicho.net',
                password: '33349962'
            }
            /*headers: cabecalho*/
        }).then(resp => {
            console.log("resposta=", resp)
            const list = this.getUpdatedList(resp.data.data)
            this.setState({ user: initialState.user, list })
        })/*.catch(function (error) {
                    
                    console.log("erro=",error);
                });*/
    }
    getUpdatedList(user, add = true) {
        const list = this.state.list.filter(u => u.id !== user.id)
        if (add) list.unshift(user)
        return list
    }

    updateField(event) {
        const user = { ...this.state.user }
        console.log(event.target.name)
        user[event.target.name] = event.target.value
        this.setState({ user })
    }





    clear() {
        this.setState({ user: initialState.user })
    }

    renderForm() {
        return (
            <AppContext.Consumer>
                {context => (
                    <div className="form">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label>Nome</label>
                                    <input type="text" className="form-control"
                                        name="nome"
                                        value={context.userOj.nome}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Digite o nome..." />
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label>E-mail</label>
                                    <input type="text" className="form-control"
                                        name="email"
                                        value={context.userOj.email}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Digite o e-mail..." />
                                </div>
                            </div>
                            <p className="linha col-12 col-md-6"> </p>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label>Senha</label>
                                    <input type="text" className="form-control "
                                        name="senha"
                                        value={context.userOj.senha}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Digite a senha..." />
                                </div>
                            </div>

                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <button className="btn btn-primary"
                                    onClick={e => this.save(e)}>
                                    Salvar ( a ser implementado)
                        </button>

                                <button className="btn btn-danger ml-2"
                                    onClick={e => this.clear(e)}>
                                    Cancelar
                        </button>
                            </div>
                            <Button variant="link" style={{ color: "black" }} onClick={e => this.takeContaCorrente(context.userOj.id)}>
                                Carregar o Conta-Corrente &nbsp;
                                <FaChevronDown  />
                            </Button>
                            {/* <button className="btn btn btn-outline-info ml-2"
                            onClick={e => this.takeContaCorrente(context.userOj.id)}>
                             Carregar o Conta-Corrente
                        </button> */}
                        </div>
                    </div>
                )}

            </AppContext.Consumer>
        )
    }

    load(user) {
        this.setState({ userOj: user })
    }

    remove(user) {
        axios.delete(`${baseUrl}/${user.id}`, {

            auth: {
                username: 'admin@jogodobicho.net',
                password: '33349962'
            }
        }).then(resp => {
            const list = this.getUpdatedList(user, false)
            this.setState({ list })
        })
    }

    renderTable() {
        return (
            <table className="table mt-4">
                <thead>
                    <tr>
                        <td>Data</td>
                        <td>Historico</td>
                        <td align="right">Valor</td>
                        <td align="right">Saldo</td>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
        )
    }
    /*
    {
            "usuarioId": "5cbe29f9c3c5df4d90c16987",
            "moedaEnum": "SHITCOIN",
            "dataEvento": "2019-04-27T17:41:35.908Z",
            "eventoEnum": "ESTORNO",
            "autenticacao": "0b982adc74cd98110324c47570ed32ec",
            "usuTransferenciaId": null,
            "valorEvento": 140.0,
            "saldoConta": 860.0,
            "ehExibivel": true,
            "id": "5cc4944fc3c5df67a8c81470"
          },
    */
    renderRows() {
        return this.state.list.map(evento => {
            const d = new Date(evento.dataEvento)
            const dia = d.toLocaleDateString() + " às: " + d.toLocaleTimeString()
            const valor = evento.eventoEnum === "APOSTA" ? -evento.valorEvento : evento.valorEvento
            const cor = valor < 0 ? "text-danger" : "text-primary"
            const pais = "pt-BR"
            const myObj = {
                minimumFractionDigits: 2
            }
            return (
                <AppContext.Consumer>
                    {context => (
                        <tr key={evento.id}>
                            <td>{dia}</td>
                            <td>{evento.eventoEnum}</td>
                            <td className={cor} align="right">{valor.toLocaleString(pais, myObj)} </td>
                            <td align="right">{evento.saldoConta.toLocaleString(pais, myObj)}</td>


                        </tr>
                    )}
                </AppContext.Consumer>
            )
        })
    }

    render() {
        return (
            <Main {...headerProps}>
                {this.renderForm()}
                {this.renderTable()}
            </Main>
        )
    }
}


