import React from "react";
import AppContext from "../../main/AppContext"; //./AppContext'
import { Button } from "react-bootstrap";
import cogoToast from "cogo-toast";
import {
  FaQuestionCircle,
  FaTrophy,
  FaShoppingCart,
  FaChevronDown,
  FaRegGem
} from "react-icons/fa";
//import {FaShoppingCart, FaCartPlus} from "react-icons/fa"
import axios from "axios";

import "./CrudAposta.css";
import ModalSubAp from "./ModalSubAp";
import {
  API_URL,
  W_STORAGE_TOKEN_KEY,
  TIPO_APOSTA,
  TIPO_ENUM,
  NumerosJogados
} from "../../consts";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import deuZebra from "../../assets/imgs/deuZebra.png";

//import { timingSafeEqual } from 'crypto';

//http://localhost:8080/api/apostas

const initialState = {
  user: { nome: "", email: "", senha: "", id: "", token: "" },
  lista: [
    {
      usuarioId: "",
      data: "",
      tipo: "SHITCOIN",
      apostaNumero: "",
      autenticacao: "autentic",
      apostaHash: "",
      valorTotAposta: 0,
      extracaoApostaDtoJ: {
        extOrigem: "RANDOMORG",
        extNumero: null,
        ehExtAgendada: false
      },
      subApostaDtoList: [{}]
    }
  ],
  ehLigado: [false, false, false, false, false, false],
  mShowModal: new Map(),
  lPremiados: [],
  premiado: {
    apostaId: "5d0d422b6477511e5840187c",
    apostaIndex: 10,
    extracaoId: "5d0d42cf64775142c87600ef",

    numSorteado: [0, 0, 0, 0, 0],

    usuarioId: "5cc0654ac3c5df635ce62f02",
    valorBonificacao: 0,
    valorGanhou: 36
  }
};
/////////////////////////////

var localStorage = window.localStorage;

////////////////////////

export default class ListaAposta extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      lista: initialState.lista,
      validated: false,
      user: initialState.user,

      token: localStorage.getItem(W_STORAGE_TOKEN_KEY),
      tokenExpiraDt: "",
      ehLigado: initialState.ehLigado,
      mostra: false,
      mShowModal: initialState.mShowModal,
      respExtracao: {},
      lPremiados: initialState.lPremiados
    };
    this.handleRepeteClick = (ap, event) => {
      event.preventDefault();
      event.stopPropagation();
      const repeteValue = { key: "aposta", aposta: ap, from: "listaAposta" };
      this.props.onSelectRepete(repeteValue);
    };
  }

  importaLista(userObj) {
    if (this.state.user.id !== "") return;
    const token = this.state.token;

    const URL = API_URL + "apostas/usuario/" + userObj.id + "?size=6&pag=0";

    axios({
      method: "GET",
      url: URL,
      //Authorization:  `Bearer ${tokenAdmin}`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
      .then(resp => {
        var lista = [];
        //cogoToast.success(`Aposta de número #${resp.data.data.apostaNumero}# .... Saldo atual:$_${resp.data.data.valorSaldo}_$  `, {hideAfter:6,heading:" Gravado com sucesso..."})
        //const respObj = parseJSON(resp)
        // console.log("resposta=",resp)

        lista = this.apostasDtoToLista(resp.data.data.content);
        console.log("resposta Ap=", lista);
        const ehLigado = lista.map(_ => false);
        this.setState({ lista, user: userObj, ehLigado });
      })
      .catch(error => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(1, error.response.data.erros); // este array de erros respondidos pela api
          cogoToast.error(
            "ATENÇÂO: ....................." + error.response.data.erros
          );
          console.log(2, error.response.status);
          console.log(3, error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          cogoToast.error("ATENÇÂO: erro no servidor \n " + error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("5 Error", error.message);
          cogoToast.error("ATENÇÂO: " + error.message);
        }
        console.log(6, error.config);
      });
    return null;
  }
  //@TODO  gravar usuario novo pelo administrador a utenticaçao está errada

  toogleLig(Ap) {
    const idx = this.state.lista.indexOf(Ap);
    const ehLig = this.state.ehLigado;
    console.log(ehLig);
    const ehLig2 = ehLig.map((x, indice) => {
      if (indice === idx) return !x;
      else return false;
    });

    this.setState({ ehLigado: ehLig2, mostra: false });
    if (ehLig2.find(x => x === true)) {
      const url = API_URL + "extracao/" + Ap.extracaoApostaDtoJ.extNumero+`?origem=${Ap.extracaoApostaDtoJ.extOrigem}`; // acresci a origem
      const token = this.state.token;
      axios.get(url).then(resp => {
        this.setState({ respExtracao: resp.data.data });
        console.log("Extracao=", resp.data.data);
      });
      //BUSCAA PREMIADOS
      const URL = API_URL + "premiados/" + Ap.id;

      axios({
        method: "GET",
        url: URL,
        //Authorization:  `Bearer ${tokenAdmin}`,

        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
        .then(resp2 => {
          var lista = [];

          lista = resp2.data.data.content;
          console.log("respostaPremiados=", resp2);
          this.setState({ lPremiados: lista });
        })
        .catch(error => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(1, error.response.data.erros); // este array de erros respondidos pela api
            cogoToast.error(
              "ATENÇÂO: ....................." + error.response.data.erros
            );
            console.log(2, error.response.status);
            console.log(3, error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            cogoToast.error("ATENÇÂO: erro no servidor \n " + error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("5 Error", error.message);
            cogoToast.error("ATENÇÂO: " + error.message);
          }
          console.log(6, error.config);
        });
    }
    this.setState({ mShowModal: new Map() });
  }

  apostasDtoToLista(respListaApostas) {
    const lista = respListaApostas.map(Ap => {
      const listaDtoSubAp = Ap.subApostaDtoList.map(subAp => {
        const tipoDto = subAp.tipoEnum;
        console.log("tipoDto $", tipoDto);
        const tipo = TIPO_APOSTA[TIPO_ENUM.indexOf(tipoDto)];
        subAp.tipoEnum = tipo;

       // console.log("tipo $", tipo);
        return subAp;
      });
      Ap.subApostaDtoList = listaDtoSubAp;

      console.log("APOSTA ", Ap);
      return Ap;
    });

    return lista;
  }

  showModal(Ap, subAp) {
    const mpShow = new Map();
    mpShow.set(subAp.index, true);
    //console.log( ehLig)

    this.setState({ mShowModal: mpShow, mostra: true });
    console.log(mpShow.get(subAp.index));
  }

  render() {
    return (
      <div>
        {this.renderContext()}
        {this.renderTable()}
      </div>
    );
  }

  renderContext() {
    return (
      <AppContext.Consumer>
        {context => <span>{this.importaLista(context.userOj)}</span>}
      </AppContext.Consumer>
    );
  }

  renderTable() {
    return (
      <table className="table mt-4" striped>
        <thead>
          <tr className="text-light bg-primary">
            <th>#</th>
            <th>Data | Hora</th>
            <th>Valor</th>
            <th>Extração | Número</th>
            {/*<th>+</th>*/}
            <th>Repetir</th>
          </tr>
        </thead>

        {this.renderRows()}
      </table>
    );
  }

  renderRows() {
    //console.log(this.state.lista)

    return this.state.lista.map((Ap, index) => {
      const d = new Date(Ap.data);
      const DIA = d.toLocaleDateString() + " | " + d.toLocaleTimeString();
      const aposta = Ap;
      var ehLigado = this.state.ehLigado;
      if (Ap.usuarioId !== "") {
        return [
          <tr key={Ap.apostaNumero}>
            <td>{Ap.apostaNumero}</td>
            <td>{DIA}</td>
            <td>{Ap.valorTotAposta}</td>
            <td>
              {Ap.extracaoApostaDtoJ.extOrigem} nº
              {Ap.extracaoApostaDtoJ.extNumero}
              <Button variant="link" onClick={() => this.toogleLig(Ap)}>
                <FaChevronDown style={{ color: "black" }} />
              </Button>
            </td>
            <td>
              {/*</td>*/}
              {/*<td>*/}
              <OverlayTrigger
                key="repetir"
                overlay={
                  <Tooltip id={`tooltip`}>Tente este jogo novamente</Tooltip>
                }
              >
                <Button
                  nada={"nada" /*className="btn-sm  btn-outline-success"*/}
                  variant="link"
                  onClick={e => this.handleRepeteClick(aposta, e)}
                >
                  <FaShoppingCart style={{ color: "green" }} />
                </Button>
              </OverlayTrigger>
            </td>
          </tr>,
          <React.Fragment>
            {ehLigado[index] ? this.renderTable2(Ap) : null}
          </React.Fragment>,
          <React.Fragment>
            {ehLigado[index] ? this.renderRows2(Ap) : null}
          </React.Fragment>,
          <React.Fragment>
            {ehLigado[index] ? (
              <tr className="text-light bg-secondary">
                <td>#</td>
                <td> . . . </td>
                <td>Apostou:
                    &sum; ${" "}{Ap.valorTotAposta} </td>
                <td> . . . </td>
                <td>
                  <strong>
                    Ganhou:
                    &sum; ${" "}
                    {this.state.mostra
                      ? this.state.lPremiados
                          .map(p => p.valorGanhou, 2)
                          .reduce(function(acumulador, atual) {
                            // ganhou
                            return acumulador + atual;
                          }, 0)
                          .toFixed(2)
                      : "???"}
                  </strong>
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        ];
        //
      } else return null;
    });
  }

  renderTable2(aposta) {
    return (
      <tr className="text-light bg-secondary">
        <th>#</th>
        <th>Tipo</th>
        <th>Valor</th>
        <th>1º|2º|3º N.</th>
        {/* <th>2º Num.</th>
                    <th>3º Num.</th>
                   */}
        <th>
          <button
            className="btn-sm btn-secondary"
            onClick={() => this.setState({ mostra: true })}
          >
            ? <FaTrophy />
          </button>
        </th>
        {/*<th>Detalhes</th>*/}
      </tr>
    );
  }

  renderRows2(aposta) {
    //console.log(this.state.lista)

    var showMdl = this.state.mShowModal;
    let modalClose = () => this.setState({ mShowModal: new Map() });
    return aposta.subApostaDtoList.map(subAp => {
      //teste de comparação
      //this.state.lPremiados.forEach((premiado,i)=> console.log(`${i}>premiados= ${premiado.apostaIndex} subindex= ${subAp.index} ?? ${subAp.index-premiado.apostaIndex}`));
      //console.log("Sub.index=",subAp.index);
      let premiado = this.state.lPremiados.find(
        premiado => (premiado.apostaIndex - subAp.index) === 0 // marretei porque a comparação não estava dando certo
      );
      let ganhou;
      if (premiado !== undefined) {
        ganhou = premiado.valorGanhou;
      } else {
        ganhou = 0;
      }
      const respExtracao = this.state.respExtracao;

      if (subAp.index !== 0) {
        return (
          <tr key={subAp.index}>
            <td> {subAp.index}</td>
            <td>{subAp.tipoEnum}</td>
            <td>{subAp.valorSubAposta}</td>
            <td>
              {" "}
              <NumerosJogados
                numeros={subAp.numerosJogados}
                tipoAposta={subAp.tipoEnum}
                cor={"#FFD"}
              />
            </td>
            {/*{this.state.mostra?<td >{ganhou? <strong className="text-danger">{`GANHOU: $ ${ganhou}`}</strong>:"Nao ganhou"}</td>: <td>???</td>}*/}

            <td>
              <Button
                variant="link"
                onClick={() => this.showModal(aposta, subAp)}
              >
                {this.state.mostra ? (
                  ganhou ? (
                    <strong className="text-danger">
                      {" "}
                      <FaRegGem /> {` $ ${ganhou}`}
                    </strong>
                  ) : (
                    <img
                      alt={"acertou?"}
                      src={deuZebra}
                      width={30}
                      color={"white"}
                    />
                  )
                ) : (
                  <FaQuestionCircle />
                )}
                {/*<FaQuestionCircle/> */}
              </Button>
            </td>
            {showMdl.get(subAp.index) !== undefined ? (
              <ModalSubAp
                extNumero={aposta.extracaoApostaDtoJ.extNumero}
                apostaNumero={aposta.apostaNumero}
                respExtracao={respExtracao}
                subAp={subAp}
                ganhou={ganhou}
                show={showMdl.get(subAp.index)}
                onHide={modalClose}
              />
            ) : null}
          </tr>
        );
      }
      return null
    });
  }
}