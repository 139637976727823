import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import Home from '../componets/home/Home'
import Ajuda from '../componets/ajuda/Ajuda'
import UserCrud from '../componets/user/UserCrud'
import ApostaMain from '../componets/apostas/ApostaMain'

export default props =>
    <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/apostas' component={ApostaMain} />
        <Route path='/users' component={UserCrud} />
        <Route path='/ajuda' component={Ajuda} />

        <Redirect from='*' to='/' />
    </Switch>