
import {IMG_BICHO} from "../../consts"

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import React, { Component } from "react";

export default class ComponenteBicho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: props.nBicho,
      cor: "#fff"
    };
  }

  /*  change = ()=> {
     this.setState({cor: this.props.sorteado?"#ff0":"#fff"})

    } */

  render() {
    return (
      <Carousel
        auto="true"
        axis="vertical"
        verticalSwipe="natural"
        width="100px"
        infiniteLoop="true"
        transitionTime={2000}
        selectedItem={this.props.nBicho}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        onChange={this.change}
        {...this.props}
      >
        {IMG_BICHO.map(bicho => {
          return (
            <img
              src={bicho}
              width={90}
              height={85}
              style={{ "background-color": this.props.cor }}
              alt="img90x85"
            />
          );
        })}
      </Carousel>
    );
  }
}