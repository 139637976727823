import React from "react";
import { BichoIco} from '../../consts';

export default function TabelaBichos() {
 
  let rows = []
  for(let i=0; i<25; i+=5){
    let col = []
        for(let j= 0;j<5; j++ ){
        col.push( <td><BichoIco nBicho={i+j+1} tamanho = {80} cor= {"#FFD"}/> </td>)
        }
      rows.push(<tr>{col} </tr>)

          
      
    }

return(rows)
}