import React from "react";
import { LinhaSorteada } from "./LinhaSorteada";

export const CincoLinhasSorteadas = ({

  cor,
  nSorteado,
  lGanhou
}) => {
    let l =[]
    for(let i=0;i<5;i++){
       l.push( <LinhaSorteada nOrd={i+1} cor={cor[i]} nSorteado={nSorteado[i]}  lGanhou={lGanhou[i]} />)
    }
    return(
        <div>
            {l}

        </div>
    );
}
CincoLinhasSorteadas.defaultProps = {

    cor: ["#FFD","#FFD","#FFD","#FFD","#FFD"],

    nSorteado: [1, 1, 1, 1, 1],
    lGanhou: [[],[],[],[],[]]

}
    