
import React from 'react'
import Main from "../templates/Main"
import Auth from "../login/Auth"
import AppContext from '../../main/AppContext' //./AppContext'
import {MdLiveHelp} from "react-icons/md"
import { Link } from 'react-router-dom'
import { LottoBicho } from '../../consts';
export default props =>
<AppContext.Consumer>
{context => (
    <Main icon="paw"   
        titleName ={LottoBicho}
        // <span className="text-primary">
        // Lo<strong  className="text-danger">tt</strong>oBicho
        // </span>}
title= " !!"
        subtitle='Um jogo do bicho  diferente!!!.'>
        <div className="display-4">Bem Vindo!!!</div>
        <hr />
        <p className="mb-1">Jogue Online / Off-line</p>
        <p className="mb=0">Seja como usuário cadastrado ou anônimo
        <Link to="/ajuda" className="user">
            <MdLiveHelp /> Saiba mais...
        </Link>
        </p>
         {/* { console.log(context)} */}
         <div>
         
          {context.userOj.nome ==="Logar" && <Auth/>}
         
         </div>
    </Main>
     ) 
         
    }</AppContext.Consumer> 
     