import React, { Component } from 'react'
import "./User-dropdown.css"
import {FaUserEdit,FaSignInAlt, FaSignOutAlt} from "react-icons/fa"
import { Link } from 'react-router-dom'
import AppContext from '../../main/AppContext'

class UserDropdown extends Component {
   /* constructor(props) {
        super(props)
        // this.state = { open: false }
        // this.name = 'UserDropdown'
        // this.components = { Gravatar }
        // this.computed = mapState(['user'])
    }*/

    logout() {
        // localStorage.removeItem(userKey)
        // this.$store.commit('setUser', null)
        // this.$router.push({ name: 'auth' })
    }

    render() {
        
        return (
            <AppContext.Consumer>
                {context => (
                    <ul class="userOj">

                        <div class="user-dropdown">
                            <div class="user-button">
                                <span class="d-sm-block pr-1 pl-1">{context.userOj.nome}</span>

                                <i class="fa fa-angle-down"></i>
                            </div>
                            <div class="user-dropdown-content">

                    {/*IF*/}  {(context.userOj.nome ==="Logar")?
                                <Link to="/" className="logIn">
                                    <FaSignInAlt/> Entrar
                                </Link>
                      /*else*/ :[ <Link to="/users" className="user">
                                   <FaUserEdit /> Sua conta..
                                </Link>,
                                <Link to="/" className="logOut"
                                onClick={() => { 
                                    context.setUserOj({ nome: 'Logar', email: '', senha: "" , id:""})
                                    context.setToken("")
                                } }>

                                    <FaSignOutAlt/> Sair
                                </Link>]
                    }
                            </div>
                        </div>
                    </ul>





                )}
            </AppContext.Consumer>



        )

    }

}

export default UserDropdown