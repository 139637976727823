//subAposta:{ index:0, tipoEnum:TIPO_APOSTA[0], numerosJogados:[1,1,1],valorSubAposta:1 },

import {toZeros,TIPO_APOSTA,NOME_BICHOS, TIPO_PAGA,TIPO_ENUM,L_MAX_N ,QT_PREMIOS , getBicho, CERCADO} from "../consts"



export const Apura = (lResultados, subAposta) => {

    if (subAposta.numerosJogados.length > 1) {
        return ApuraTD(lResultados, subAposta)
    }

    const indexTipoAp = TIPO_ENUM.indexOf(subAposta.tipoEnum)

   // const ultimoPremio = QT_PREMIOS(indexTipoAp)

   const ultimoPremio = CERCADO[indexTipoAp]
    const aResultados = lResultados
console.log("ultimo premio ..............................", ultimoPremio)
console.log("index premio ..............................", subAposta.tipoEnum)
 
    let grupo = aResultados.map(p => getBicho(p))
    const ehGrupo = L_MAX_N[indexTipoAp] === 25
    const sorteados = (ehGrupo) ? grupo : aResultados
    let aAcertos = []
    let ganhou = 0;
    let txGanhou = "Aposta nº " + subAposta.index + " " + TIPO_APOSTA[indexTipoAp];
    const Qt_Zeros = (ehGrupo) ? 100 : L_MAX_N[indexTipoAp] + 1;
    const digits = Qt_Zeros.toString().length-1;
    txGanhou += `\n Apostou $${subAposta.valorSubAposta} no [${toZeros(subAposta.numerosJogados[0],digits)}] - ${(ehGrupo) ? NOME_BICHOS[subAposta.numerosJogados[0] - 1] : ""}`

    for (let i = 0; i < ultimoPremio; i++) {


        const numSorteado = sorteados[i] % Qt_Zeros
        //console.log("resultados array", aResultados[i], numSorteado)
        const strNumSorteio = toZeros(aResultados[i],4)
        if (subAposta.numerosJogados[0] === numSorteado) {
            const ganhouAqui = subAposta.valorSubAposta * TIPO_PAGA[indexTipoAp]
            ganhou += ganhouAqui
            // eslint-disable-next-line react/react-in-jsx-scope

            // eslint-disable-next-line react/react-in-jsx-scope
            txGanhou += `\n${i + 1}º ${strNumSorteio} / [${toZeros(grupo[i],2)}]:${NOME_BICHOS[grupo[i]-1]} <<ACERTOU>> Ganhou $${ganhouAqui}`
            aAcertos.push({premio: i, tipo: TIPO_APOSTA[indexTipoAp], resultado: numSorteado, ganhou: ganhouAqui})
        } else {
            txGanhou += `\n${i + 1}º ${strNumSorteio} / [${toZeros(grupo[i],2)}]:${NOME_BICHOS[grupo[i]-1]} :( Não acertou ... )`
        }
    }

    return {ganhouAqui: ganhou, txGanhou, aAcertos}
};


const ApuraTD = (lResultados, subAposta) => {

    const indexTipoAp = TIPO_ENUM.indexOf(subAposta.tipoEnum)

    const ultimoPremio = QT_PREMIOS(indexTipoAp)


    const aResultados = lResultados
    let grupo = aResultados.map(p => getBicho(p))

    const ehGrupo = L_MAX_N[indexTipoAp] === 25
    const sorteados = (ehGrupo) ? grupo : aResultados
    let ehPrimeiroPremio = false
    let ehPasseVai = false
    let nAcertos = 0
    let aAcertos = []
    let aNumJogados = subAposta.numerosJogados.filter(n=> {return true})

    let txGanhou = "Aposta nº " + subAposta.index + " " + TIPO_APOSTA[indexTipoAp];

    const ganhouAqui= subAposta.valorSubAposta * TIPO_PAGA[indexTipoAp]

    for (let i = 0; i < aNumJogados.length; i++) {
        let strInicial = `\n Apostou $${subAposta.valorSubAposta}`
        const strAlt = "\n" +"_".repeat(strInicial.length+1)
        strInicial= i===0?strInicial:strAlt
        if (ehGrupo) {
            txGanhou += `${strInicial}> no [${toZeros(aNumJogados[i])}] - ${NOME_BICHOS[aNumJogados[i] - 1]}`
        } else {
            txGanhou += `${strInicial}> no [${aNumJogados[i]}] `
        }
    }

    for( let i=0 ; i<ultimoPremio; i++){
        const Qt_Zeros = (ehGrupo)?100:L_MAX_N[indexTipoAp]+1;
        const numSorteado = sorteados[i] % Qt_Zeros;

       let strNumSorteio =  toZeros(aResultados[i],4);

        const iAcertou =  aNumJogados.findIndex(n=> n===numSorteado);
        if (iAcertou !== -1) {
            //{ tipo: "Grupo", resutado:this.state.b1+1, ganhou: 6}
            aAcertos.push({
                              premio: i,
                              tipo: TIPO_APOSTA[indexTipoAp],
                              resultado: numSorteado,
                              ganhou: ganhouAqui / subAposta.numerosJogados.length
                          })
            aNumJogados = aNumJogados.filter((n, index) => {
                return index !== iAcertou
            }) // retira o que já acertou
            nAcertos++
            txGanhou += `\n${i + 1}º ${strNumSorteio}  / [${toZeros(grupo[i], 2)}]:${NOME_BICHOS[grupo[i] - 1]}<Acertou> ${nAcertos}ª vez`
            // passe vai e vaivem
            if (i === 0) {
                ehPrimeiroPremio = true
                ehPasseVai = iAcertou === 0
            }
        } else {
            txGanhou += `\n${i + 1}º ${strNumSorteio}  / [${toZeros(grupo[i], 2)}]:${NOME_BICHOS[grupo[i] - 1]}:( Não acertou ) `
        }
    }


    switch(TIPO_ENUM[indexTipoAp]) {
        case "DUQUE":
        case "DUQUE_D":
            if (nAcertos > 1) {
                txGanhou += `\n  <<ACERTOU>> no ${TIPO_APOSTA[indexTipoAp]} Ganhou $${ganhouAqui}`;
                return { ganhouAqui, txGanhou, aAcertos }
            }
            break;
        case "TERNO":
        case "TERNO_D":
            if (nAcertos > 2) {
                txGanhou += `\n  <<ACERTOU>> no ${TIPO_APOSTA[indexTipoAp]} Ganhou $${ganhouAqui}`;
                return { ganhouAqui, txGanhou, aAcertos }
            }
            break;
        case "PASSE_VAIVEM":
            if (nAcertos > 1 && ehPrimeiroPremio) {
                txGanhou += `\n  <<ACERTOU>> no ${TIPO_APOSTA[indexTipoAp]} Ganhou $${ganhouAqui}`;
                return { ganhouAqui, txGanhou, aAcertos }
            }
            break;
        case "PASSE_VAI":
            if (nAcertos > 1 && ehPasseVai) {
                txGanhou += `\n  <<ACERTOU>> no ${TIPO_APOSTA[indexTipoAp]} Ganhou $${ganhouAqui}`;
                return { ganhouAqui, txGanhou, aAcertos }
            }
            break;
        default : break;

    }

    txGanhou += `\n:( NÃO ACERTOU no ${TIPO_APOSTA[indexTipoAp]} )`
    return { ganhouAqui:0 , txGanhou, aAcertos:[] }

}/* fim função ApuraTD */

