import React from 'react'

const AppContext = React.createContext({
   
    userOj : {},

    token : "",
    tokenExpiraDt: "" ,
    extracaoRandom: "",
    dataRandom: "",
    deltaRandom:"",
    localSaldo:"",
})
export default AppContext