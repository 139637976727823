import React from "react";
import AppContext from "../../main/AppContext"; //./AppContext'
import { Form, Col, Button } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { FaShoppingCart, FaCartPlus,FaEraser } from "react-icons/fa";
import axios from "axios";
import {
  API_URL,
  L_MAX_N,
  L_MIN_N,
  NumerosJogados,
  TIPO_APOSTA,
  TIPO_ENUM,
  W_STORAGE_TOKEN_KEY
} from "../../consts";
import "./ListaApostas";
//import { timingSafeEqual } from 'crypto';

const baseUrl = API_URL + "apostas";
//http://localhost:8080/api/apostas

const initialState = {
  user: { nome: "", email: "", senha: "", id: "", token: "" },

  aposta: {
    usuarioId: "132",
    data: "",
    tipo: "SHITCOIN",
    apostaNumero: "0",
    autenticacao: "autentic",
    apostaHash: "",
    valorTotAposta: 0,
    extracaoApostaDtoJ: {
      extOrigem: "RANDOMORG",
      extNumero: null,
      ehExtAgendada: false
    },
    subApostaDtoList: [{}]
  },

  extracaoApostaDtoJ: {
    extOrigem: "RANDOMORG",
    extNumero: null,
    ehExtAgendada: false
  },
  subAposta: {
    index: 0,
    tipoEnum: TIPO_APOSTA[0],
    numerosJogados: [1, 1, 1],
    valorSubAposta: 1
  },

  //lista: [{ "index":0, "tipoEnum":"0-Grupo", "numerosJogados":[1,0,0],"valorSubAposta":1}]
  lista: [
    {
      index: 0,
      tipoEnum: "0-Grupo",
      numerosJogados: [1, 1, 1],
      valorSubAposta: 1
    }
  ]
};

var localStorage = window.localStorage;

////////////////////////

export default class CrudAposta extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // lista: (props.aposta !== null)? {... props.aposta.subApostaDtoList}: initialState.lista,
      lista: initialState.lista,
      validated: false,
      user: initialState.user,
      subAposta: initialState.subAposta,
      aposta: props.aposta !== null ? props.aposta : initialState.aposta,
      extracaoApostaDtoJ: initialState.extracaoApostaDtoJ,
      ocultaHora: false,
      deltaRandom: 0,
      ehAlteracao: false,
      temN2: false,
      temN3: false,
      maxNum: 25,
      minNum: 1,
      token: localStorage.getItem(W_STORAGE_TOKEN_KEY),
      tokenExpiraDt: "",
      ultimaFederal: 0
    };
  }

 async componentWillMount() {


      var resposta =0
      
      await axios.get(API_URL+'extracao/lista?origem=FEDERAL',{timeout:3000})
          .then(response=> {
          console.log(`resposta federal ${response.data.data.content[0].serialNumber}`)
          resposta=response.data.data.content[0].serialNumber}
          ).catch(()=>cogoToast.warn('Servidor não responde'))
          
          
          
      
      console.log(`Ultima federal ${resposta}`)
      this.setState({ ultimaFederal : resposta})
    //////////////////////
    console.log("this.props", this.props);
    if (this.props.aposta !== null && this.props.aposta !== undefined) {
      const aposta = this.props.aposta;

      var listaSubAp = aposta.subApostaDtoList.map(sub => {
        return sub;
      });
      const subAp = listaSubAp[0];
      const tipoAp = subAp.tipoEnum;
      aposta.extracaoApostaDtoJ.extNumero = this.context.extracaoRandom + 1;
      this.setState({
        lista: listaSubAp,
        aposta: aposta,
        extracaoApostaDtoJ: this.props.aposta.extracaoApostaDtoJ,
        subAposta: subAp
      });
      this.updateTipoAposta(null, tipoAp);
      //  this.setMinMaxTen1e2(tipoAp)
    }
  }

  handleSubmit(event, extracaoRandom, userObj, setUserOj) {
    const form = event.currentTarget;
    const ehChk = form.checkValidity();
    event.preventDefault();
    event.stopPropagation();
    console.log(ehChk);
    if (!ehChk) {
      cogoToast.error("Corrija e marque em [x] Aceitar: ", {
        hideAfter: 6,
        heading: " ERRO DE PREENCHIMENTO"
      });
      this.setState({ validated: true });
      return;
    } else
      cogoToast.loading(" Aguarde confirmacao ...", {
        hideAfter: 3,
        heading: " ENVIANDO APOSTA ..."
      });

    event.preventDefault();
    event.stopPropagation();

    //ROTINA DE POST

    var apostaDto = { ...this.state.aposta };

    delete apostaDto.autenticacao;
    delete apostaDto.apostaHash; // verificar se vou gerar hash
    delete apostaDto.apostaNumero;
    delete apostaDto.id; // senão fará update
    var extAp = { ...this.state.extracaoApostaDtoJ };
    apostaDto.subApostaDtoList = " ";

    if (extAp.extNumero == null) extAp.extNumero = extracaoRandom + 1;
    //extracaoApostaDtoJ: {extOrigem:"RANDOMORG",extNumero:null,ehExtAgendada: false},
    extAp = {
      ehExtAgendada: extracaoRandom + 1 !== extAp.extNumero,
      extNumero: this.state.extracaoApostaDtoJ.extOrigem==="FEDERAL"?  this.state.ultimaFederal+1 : extAp.extNumero,
      extOrigem: this.state.extracaoApostaDtoJ.extOrigem
    };

    apostaDto.extracaoApostaDtoJ = extAp;
    const uId = userObj.id;
    apostaDto.usuarioId = uId;
    console.log(userObj);
    console.log(JSON.stringify(apostaDto, 1));

    var cSubList = '"subApostaDtoList":[';

    for (let i = 0; i < this.state.lista.length; i++) {
      const obj = { ...this.state.lista[i] };

      const nt = obj.tipoEnum.split("-")[0];
      obj.tipoEnum = TIPO_ENUM[nt];

      cSubList += JSON.stringify(obj) + ",";
      console.log("lista sub:" + obj.toString());
      obj.tipoEnum = TIPO_APOSTA[nt];
    }
    if (apostaDto.extracaoApostaDtoJ.extOrigem === "LOCAL") {
      apostaDto.subApostaDtoList = [...this.state.lista];
      const repeteValue = { key: "local", aposta: apostaDto, from: "aposta" };
      this.props.onSelectRepete(repeteValue);

      return;
    }

    cSubList += "]";
    cSubList = cSubList.replace(",]", "]");
    // s.replace(/([.*+?^$|(){}\[\]])/mg, "\\$1");
    const cJSon = JSON.stringify(apostaDto, 1)
      .replace('"subApostaDtoList":" "', cSubList)
      .replace(/"-"/gm, "0");
    console.log(cJSon);
    const token = this.state.token;

    //axios
    axios({
      method: "POST",
      url: baseUrl,
      //Authorization:  `Bearer ${tokenAdmin}`,
      data: cJSon,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
      .then(resp => {
        console.log("Gravado com sucesso");
        cogoToast.success(
          `Aposta de número #${resp.data.data.apostaNumero}# .... Saldo atual:$_${resp.data.data.valorSaldo}_$  `,
          { hideAfter: 6, heading: " Gravado com sucesso..." }
        );
        let user = { ...userObj };
        user.mapSaldo[apostaDto.tipo] = resp.data.data.valorSaldo;
        setUserOj(user);
        //const respObj = parseJSON(resp)
        console.log("resposta=", resp);
      })
      .catch(error => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(1, error.response.data.erros); // este array de erros respondidos pela api
          cogoToast.error(
            "ATENÇÂO: ....................." + error.response.data.erros
          );
          console.log(2, error.response.status);
          console.log(3, error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          cogoToast.error("ATENÇÂO: erro no servidor \n " + error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("5 Error", error.message);
          cogoToast.error("ATENÇÂO: " + error.message);
        }
        console.log(6, error.config);
      });

    this.setState({ validated: false });
  }

  //fim DO POST

  updateExtOrigem(event) {
    const extracaoApostaDtoJ = { ...this.state.extracaoApostaDtoJ };

    extracaoApostaDtoJ.extOrigem = event.target.value;

    this.setState({
      extracaoApostaDtoJ,
      ocultaHora: event.target.value !== "RANDOMORG",
      isNotRANDOMORG: event.target.value !== "RANDOMORG"
    });
  }
  updateExtNum(event, delta) {
    const extracaoApostaDtoJ = { ...this.state.extracaoApostaDtoJ };

    extracaoApostaDtoJ.extNumero = event.target.value;

    this.setState({ extracaoApostaDtoJ, deltaRandom: delta });
  }
  updateTipoAposta(event, tipoSubAp) {
    var subApostaUpdt = { ...this.state.subAposta };
    var tipoAp = "";
    if (event !== null) tipoAp = event.target.value;
    else tipoAp = tipoSubAp; // @todo alterar para receber como parametro e se nul ou undefine este valor padrao
    subApostaUpdt.tipoEnum = tipoAp;
    console.log("subApostaUpdt = ", subApostaUpdt.tipoEnum);
    this.setMinMaxTen1e2(tipoAp);
    this.setState({ subAposta: subApostaUpdt });
  }

  setMinMaxTen1e2(tipoAp) {
    const nt = tipoAp ? tipoAp.split("-")[0] : 0;
    var temN2 = true;
    var temN3 = true;
    var maxNum = Number(L_MAX_N[nt]);
    var minNum = Number(L_MIN_N[nt]);
    console.log("max/min:", minNum, maxNum);
    if (nt < 8) {
      temN2 = false;
      temN3 = false;
    } else if (!nt.match("10|11")) temN3 = false;

    this.setState({ temN2, temN3, maxNum, minNum });
  }

  updateValorAposta(event) {
    var subApostaUpdt = { ...this.state.subAposta };
    subApostaUpdt.valorSubAposta = event.target.value;

    this.setState({ subAposta: subApostaUpdt });
  }
  updateNAposta(event, i) {
    var subApostaUpdt = { ...this.state.subAposta };
    const num = [...subApostaUpdt.numerosJogados];
    const n = event.target.value;
    num[i] = n;
    subApostaUpdt.numerosJogados = num;
    this.setState({ subAposta: subApostaUpdt });
  }

  adiciona(event) {
    console.log(event.target.value);
    //@todo validar se num e valor
    const subAp = { ...this.state.subAposta };
    //subAp.tipoEnum= event.target.value
    const MAX = L_MAX_N[subAp.tipoEnum.split("-")[0]];
    const MIN = L_MIN_N[subAp.tipoEnum.split("-")[0]];

    if ((subAp.valorSubAposta < 1) | (subAp.valorSubAposta > 1000)) {
      cogoToast.error(
        "O valor da aposta deve ser maior que 0 e menor que 1000",
        { hideAfter: 6, heading: " ERRO DE PREENCHIMENTO" }
      );
      this.setState({ validated: true });
      return;
    }
    if ((subAp.numerosJogados[0] < MIN) | (subAp.numerosJogados[0] > MAX)) {
      cogoToast.error(
        "Para tipo = '" +
        this.state.subAposta.tipoEnum +
        "' o valor do '1º Num'. deve ser entre " +
        MIN +
        " e " +
        MAX,
        { hideAfter: 6, heading: " ERRO DE PREENCHIMENTO" }
      );
      this.setState({ validated: true });
      return;
    }

    if (
      this.state.temN2 &
      ((subAp.numerosJogados[1] === "-") |
        (subAp.numerosJogados[1] < MIN) |
        (subAp.numerosJogados[1] > MAX))
    ) {
      cogoToast.error(
        "Para tipo = '" +
        this.state.subAposta.tipoEnum +
        "' o valor do '2º Num'. deve ser entre " +
        MIN +
        " e " +
        MAX,
        { hideAfter: 6, heading: " ERRO DE PREENCHIMENTO" }
      );
      this.setState({ validated: true });
      return;
    }
    if (
      this.state.temN3 &
      ((subAp.numerosJogados[2] === "-") |
        (subAp.numerosJogados[2] < MIN) |
        (subAp.numerosJogados[2] > MAX))
    ) {
      cogoToast.error(
        "Para tipo = '" +
        subAp.tipoEnum +
        "' o valor do '3º Num'. deve ser entre " +
        MIN +
        " e " +
        MAX,
        { hideAfter: 6, heading: " ERRO DE PREENCHIMENTO" }
      );
      this.setState({ validated: true });
      return;
    }

    var listaNew = this.state.lista.filter(ap => ap.index !== 0);
    if (!this.state.ehAlteracao) {
      var idx = listaNew.length < 1 ? 1 : listaNew[0].index + 1;
      subAp.index = idx;
    }

    const ap = { ...this.state.aposta };
    const soma = Number(ap.valorTotAposta) + Number(subAp.valorSubAposta);
    ap.valorTotAposta = soma;

    var aNumJ = [...subAp.numerosJogados];
    if (!this.state.temN2) subAp.numerosJogados = [aNumJ[0], "-", "-"];
    else if (!this.state.temN3)
      subAp.numerosJogados = [aNumJ[0], aNumJ[1], "-"];
    listaNew.unshift(subAp);
    //listaNew.sort()
    for (var i = 0; i < listaNew.length; i++) {
      listaNew[i].index = listaNew.length - i;
    }
    // subAp.index=listaNew[0].index

    this.setState({
      lista: listaNew,
      aposta: ap,
      subAposta: subAp,
      ehAlteracao: false,
      validated: false
    });
  }

  limpar(){
    this.setState({ 
      lista: initialState.lista,
      subAposta: initialState.subAposta,
      aposta: initialState.aposta,
      extracaoApostaDtoJ: initialState.extracaoApostaDtoJ,

     })
  }

  remove(subAp) {
    //var subAp = { ...this.state.subAposta }
    let menos;
    var listaNew = this.state.lista.filter(
      ap => (ap.index !== 0) & (ap !== subAp)
    );

    console.log(`tamanho da lista=${listaNew.length}`)
    
    
    if( listaNew.length===0){
      this.setState({ 
        lista: initialState.lista,
        subAposta: initialState.subAposta,
        aposta: initialState.aposta,
        extracaoApostaDtoJ: initialState.extracaoApostaDtoJ,

       })
    }else{

    for (var i = 0; i < listaNew.length; i++) {
      listaNew[i].index = listaNew.length - i;
    }

    const ap = { ...this.state.aposta };
    menos = Number(ap.valorTotAposta) - Number(subAp.valorSubAposta);
    ap.valorTotAposta = menos;
    
    this.setState({ lista: listaNew, aposta: ap, subAposta: listaNew[0] }); // alterei aqui
  }
  }

  altera(subAp) {
    //var subAp = { ...this.state.subAposta }
    let menos;
    var listaNew = this.state.lista.filter(
      ap => (ap.index !== 0) & (ap !== subAp)
    );
    const ap = { ...this.state.aposta };
    menos = Number(ap.valorTotAposta) - Number(subAp.valorSubAposta);
    ap.valorTotAposta = menos;
    const tipoAp = subAp.tipoEnum;
    this.setMinMaxTen1e2(tipoAp);
    this.setState({
      lista: listaNew,
      aposta: ap,
      subAposta: subAp,
      ehAlteracao: true
    });
  }

  render() {
    const { validated } = this.state;
    return (
      <div>
        <Form
          Form
          noValidate
          validated={validated}
          onSubmit={e => this.handleSubmit(e)}
        >
          {this.renderForm()}
        </Form>
        {this.renderTable()}
      </div>
    );
  }

  renderForm() {
    const { validated } = this.state;
    //@TODO consertar marretacao

    const MAX = L_MAX_N[this.state.subAposta.tipoEnum.split("-")[0]];
    const MIN = L_MIN_N[this.state.subAposta.tipoEnum.split("-")[0]];
    return (
      <AppContext.Consumer>
        {context => (
          //[<a  name="saldo"> Saldo {context.dataRandom}</a>,
          //<div className="text-primary" size="sm" mt="1" mb="1"   >
          <Form
            noValidate
            validated={validated}
            onSubmit={e =>
              this.handleSubmit(
                e,
                context.extracaoRandom,
                context.userOj,
                context.setUserOj
              )
            }
          >
            <Form.Row>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Extr.Orig</Form.Label>
                <Form.Control
                  as="select"
                 // defaultValue={this.state.extracaoApostaDtoJ.extOrigem}
                  required
                  type="text"
                  name="extracaoApostaDtoJ.extOrigem"
                  onChange={e => this.updateExtOrigem(e, context.deltaRandom)}
                >
                  {this.props.from==='local'
                  ?<>
                      <option >LOCAL</option>
                      <option>RANDOMORG</option>
                      <option>FEDERAL</option>  
                    </>
                  :<>
                    <option>RANDOMORG</option>
                    <option>FEDERAL</option>  
                    <option >LOCAL</option>
                   </>
                      }
          
                </Form.Control>
                <Form.Control.Feedback>Ok!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom02">
          <Form.Label>Extr.Nº {this.state.extracaoApostaDtoJ.extOrigem=="FEDERAL" && 
                (<span><strong>{this.state.ultimaFederal+1}.</strong>  (próxima extração)</span>) }</Form.Label>
                
                <Form.Control
                  required
                  disabled={this.state.isNotRANDOMORG}
                  style={this.state.isNotRANDOMORG?{backgroundColor:"#999"}:{backgroundColor:"#FFF"}}
                  type="number"
                  min={context.extracaoRandom + 1}
                  max={context.extracaoRandom + 1000}
                  name="extNumero"
                  defaultValue={context.extracaoRandom + 1}
                  onChange={e => this.updateExtNum(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Tem que ser maior que {context.extracaoRandom + 1} e menor que {context.extracaoRandom + 1001}.
                </Form.Control.Feedback>
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
              </Form.Group>

              {/* 
              <Form.Group as={Col} md="2" controlId="validationCustomDate">
                <Form.Label>Dia</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    
                  </InputGroup.Prepend>
                  <Form.Control
                    type="date"
                    id="dataExtracao"
                    disabled={this.state.isNotRANDOMORG}
                    aria-describedby="inputGroupPrepend"
                  // defaultValue={ new Date(Date.parse(context.dataRandom)+ context.deltaRandom).toISOString() }
                  //value={ new Date(Date.parse(context.dataRandom)+ context.deltaRandom).toISOString().substring(0, 10) }
                  />
                  <Form.Control.Feedback type="invalid">
                    Data inválida ...
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              
              <Form.Group as={Col} md="2" controlId="validationCustomTime">
                <Form.Label>Hora</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                   </InputGroup.Prepend>
                  <Form.Control
                    type="time"
                    aria-describedby="inputGroupPrepend"
                    disabled={this.state.ocultaHora}
                  //value={new Date(Date.parse(context.dataRandom)).getHours().toString()+":"+
                  //new Date(Date.parse(context.dataRandom)+ context.deltaRandom).getMinutes().toString() }
                  />
                  <Form.Control.Feedback type="invalid">
                    Hora/min inválidos
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
               */}

              <Form.Group as={Col} md="2" controlId="validationCustom03">
                <Form.Label>Tot.Aposta</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min="1"
                  value={this.state.aposta.valorTotAposta}
                  disabled={this.state.aposta.valorTotAposta !== 0}
                />
                <Form.Control.Feedback type="invalid">
                  Deve conter pelo menos uma aposta
                </Form.Control.Feedback>
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="1">
                <Form.Label>#</Form.Label>
                <Form.Control
                  type="number"
                  disabled
                  value={this.state.subAposta.index}
                />
              </Form.Group>

              <Form.Group as={Col} md="3" pl="1" controlId="validationCustom04">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  as="select"
                  required
                  type="text"
                  //value ={this.state.subAposta.tipoEnum}
                  // value ="2-Milhar                   "
                  onChange={e => this.updateTipoAposta(e)}
                >
                  {TIPO_APOSTA.map(tp => {
                    return tp === this.state.subAposta.tipoEnum ? (
                      <option selected>{tp}</option>
                    ) : (
                        <option>{tp}</option>
                      );
                  })}

                  {/*  <option>Escolha uma aposta</option> 
              <option>0-Grupo                    </option> 
              <option>1-Grupo 1/5                </option>
              <option>2-Milhar                   </option>
              <option>3-Milhar 1/5               </option>
              <option>4-Centena                  </option>
              <option>5-Centena 1/5              </option>
              <option>6-Dezena                   </option>
              <option>7-Dezena 1/5               </option>
              <option>8-Dupla/Duque de grupo 1/5</option>
              <option>9-Duque de dezena 1/5        </option>
              <option>10-Terno de grupo 1/5      </option>
              <option>11-Terno de dezena 1/5        </option>
              <option>12-Passe Vai-vem (PVV)          </option>
              <option>13-Passe Vai (PV)          </option> */}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="validationCustom05">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min="1"
                  max="1000"
                  value={this.state.subAposta.valorSubAposta}
                  onChange={e => this.updateValorAposta(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Deve ser entre $1 e $1.000.
                </Form.Control.Feedback>
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="validationCustom09">
                <Form.Label>1º Num</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min={MIN}
                  max={MAX}
                  value={this.state.subAposta.numerosJogados[0]}
                  onChange={e => this.updateNAposta(e, 0)}
                />
                <Form.Control.Feedback type="invalid">
                  Deve estar entre {MIN} e {MAX}.
                </Form.Control.Feedback>
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="validationCustom06">
                <Form.Label>2º Num</Form.Label>
                <Form.Control
                  disabled={!this.state.temN2}
                  type="number"
                  style={!this.state.temN2?{backgroundColor:"#999"}:{backgroundColor:"#FFF"}}
                  min={MIN}
                  max={MAX}
                  value={this.state.subAposta.numerosJogados[1]}
                  onChange={e => this.updateNAposta(e, 1)}
                />
                <Form.Control.Feedback type="invalid">
                  Deve estar entre {MIN} e {MAX}.
                </Form.Control.Feedback>
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="validationCustom07">
                <Form.Label>3º Num</Form.Label>
                <Form.Control
                  disabled={!this.state.temN3}
                  type="number"
                  style={!this.state.temN3?{backgroundColor:"#999"}:{backgroundColor:"#FFF"}}
                  min={MIN}
                  max={MAX}
                  value={this.state.subAposta.numerosJogados[2]}
                  onChange={e => this.updateNAposta(e, 2)}
                />
                <Form.Control.Feedback type="invalid">
                  Deve estar entre {MIN} e {MAX}.
                </Form.Control.Feedback>
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Check
                required={!this.state.isNotRANDOMORG}
                name="radios"
                label="Aceito os termos e declaro ter mais de 18 anos"
                defaultChecked={false}
              />
            </Form.Group>

            <div className="Button">
              <Button  variant="secondary" className="mr-2" onClick={e => this.limpar()}>
                <FaEraser /> Limpar
              </Button>

              <Button type="submit" variant="warning">
                <FaShoppingCart /> Finaliza
              </Button>

              <Button className="ml-2" onClick={e => this.adiciona(e)}>
                <FaCartPlus /> +Aposta
              </Button>
            </div>
          </Form>

          //</div>]
        )}
      </AppContext.Consumer>
    );
  }

  renderTable() {
    return (
      <table className="table mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Tipo</th>
            <th>Val</th>
            <th>Números</th>

            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }

  renderRows() {
    //console.log(this.state.lista)

    return this.state.lista.map(subAp => {
      if (subAp.index !== 0) {
        return (
          <tr key={subAp.index}>
            <td> {subAp.index}</td>
            <td>{subAp.tipoEnum}</td>
            <td>{subAp.valorSubAposta}</td>
            <td>
              {" "}
              <NumerosJogados
                numeros={subAp.numerosJogados}
                tipoAposta={subAp.tipoEnum}
                cor={"#FFD"}
              />
            </td>

            <td>
              {/*<button className="btn btn-outline-secondary ml-1"*/}
              <Button variant="link" onClick={() => this.altera(subAp)}>
                <i className="fa fa-pencil" />
              </Button>
              <Button variant="link" onClick={() => this.remove(subAp)}>
                <i className="fa fa-trash" style={{ color: "red" }} />
              </Button>
            </td>
          </tr>
        );
      }
      return null
    });
  }
}   