import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import "./App.css"
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Logo from "../componets/templates/Logo"
import Nav from "../componets/templates/Nav"
import Userdropdown from '../componets/login/User-dropdown'

import Footer from '../componets/templates/Footer'
import ExtracaoBox from '../componets/templates/ExtracaoBox'
import Routes from "./Routes"
import AppContext from './AppContext'
import axios from 'axios'
import cogoToast from "cogo-toast"
import { API_URL} from "../consts";

const iniciaUserOj =JSON.parse(`
{
"nome": "Logar",
"email": "",
"senha": "",
"ativoStatus": "ATIVO",
"contaBitCoin": null,
"contaPlayStore": null,
"mapSaldo": {
              "SHITCOIN": 1000.0
            },
"id": ""
} 
`)
const W_STORAGE_TOKEN_KEY ="_LB_TOKEN"
const W_STORAGE_LOCAL_SALDO="_LB_SLD"

var localStorage =  window.localStorage
var  localSaldoInit =localStorage.getItem( W_STORAGE_LOCAL_SALDO)
if(!localSaldoInit){
    //this.setState({ localSaldoInit: newSaldo })
    localSaldoInit= 1000
    localStorage.setItem( W_STORAGE_LOCAL_SALDO,localSaldoInit )
}
export default class App extends React.Component {

    state = { 
        userOj: iniciaUserOj,
        token: localStorage.getItem( W_STORAGE_TOKEN_KEY),
        tokenExpiraDt: "", 
        extracaoRandom: 13500,
        dataRandom: Date(),
        deltaRandom: 0,
        localSaldo: localSaldoInit ,
    }

    setUserOj = user => {
        this.setState({ userOj: user })
    }
   
    setToken     = newToken => {
        this.setState({ token: newToken })
        localStorage.setItem( W_STORAGE_TOKEN_KEY, newToken);
    }
    setLocalSaldo = newSaldo => {
        this.setState({ localSaldo: newSaldo })
        localStorage.setItem( W_STORAGE_LOCAL_SALDO,newSaldo )

    }
    setExtracaoRandom = extracaoNow => { 
        this.setState({ extracaoRandom : extracaoNow }) 
    }

    setDataRandom = dataNow => { 
        this.setState({ dataRandom : dataNow })
    }
    
    setTokenExpiraDt = dtFim => { 
        this.setState({ tokenExpiraDt: dtFim })
    }
       
    setDeltaRandom= dlt => {
        this.setState({deltaRandom:dlt})
    }

    render() {
        return (
            <AppContext.Provider value={{
                userOj: this.state.userOj,
                token: this.state.token,
                tokenExpiraDt: this.state.tokenExpiraDt,
                setUserOj: this.setUserOj,
                setToken: this.setToken,
                setTokenExpiraDt: this.setTokenExpiraDt,

                dataRandom: this.state.dataRandom,
                deltaRandom: this.state.deltaRandom,
                setDeltaRandom: this.setDeltaRandom,
                setDataRandom: this.setDataRandom,
                extracaoRandom: this.state.extracaoRandom,
                setExtracaoRandom: this.setExtracaoRandom,
                localSaldo: this.state.localSaldo,
                setLocalSaldo: this.setLocalSaldo,
             
                }}>

                <BrowserRouter>
                    <div className="app">
                        <Logo />
                        <Nav />
                        <Userdropdown />
                        <ExtracaoBox
                            setExtracaoRandom={this.setExtracaoRandom}
                                setDataRandom={this.setDataRandom}
                                setDeltaRandom={this.setDeltaRandom}/>
                        <Routes />
                        <Footer />
                    </div>
                </BrowserRouter>
            </AppContext.Provider>
        )
    }

    componentDidMount() {
        const  token_W = localStorage.getItem( W_STORAGE_TOKEN_KEY)
        if(token_W){
            axios({
                method: "GET",
                url: API_URL+"usuario/token",
                headers: {'Authorization': `Bearer ${token_W}`}
               /*headers: cabecalho*/
              }).then(resp => {
            
           console.log("usuario_TK=", resp.data.data)
            this.setUserOj(resp.data.data)
            cogoToast.info("Bem Vindo : "+resp.data.data.nome)
            
        })}
    }  

}