import React, { Component } from "react";

import "../../lib/odometer-theme-digital.css";
import "../../lib/odometer-theme-slot-machine.css";
import Odometer from "react-odometerjs";

import { getMassaDeAposta } from "../../utils/MassaDeApostas";
import { Apura } from "../../utils/Apura";
import { TIPO_ENUM, L_MAX_N } from "../../consts";
import { FaDice, FaAngleDown, FaReply, FaSyncAlt } from "react-icons/fa";
import { Button,  } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import slotIcon from "../../assets/imgs/slot-machine-icons-sm-white.png";
import { CincoLinhasSorteadas } from "../telaSorteio/CincoLinhasSorteadas";
import './ExtracaoLocal.css'


function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default class ExtracaoLocal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipo: this.props.tipo,
            nome: this.props.nome,
            // showCount: null,
            paused: true,
            size: 100,
            nB: [0, 0, 0, 0, 0],
            timeToCount: 2,
            delta: 0.1,
            sorteado1: false,
            sorteado2: false,
            cor: ["#FFD", "#FFD", "#FFD", "#FFD", "#FFD"],
            nRandom: [1, 1, 1, 1, 1],

            saldo: props.saldo,
            corSpin: "green",
            lGanhou: [[], [], [], [], []],
            mostraDetalhe: false,
            displayButton: "none",
            divDetalhes: [],
            valorTotAposta: this.props.aposta ? this.props.aposta.valorTotAposta : 0,
            totGanho: 0
        };

        /*this.setN1 = this.setN1.bind(this)
            this.setN2 = this.setN2.bind(this)*/
    }

    apura = () => {
        // if(this.state.showCount===null) return // retorna na primeira vez do render sem apurar nada
        let subApostas = [];
        if (this.props.aposta) {
            subApostas = this.props.aposta.subApostaDtoList.map(subAp => {
                let subApLocal = { ...subAp };

                const numJogados = subApLocal.numerosJogados.filter(e => e !== "-").map(n=> parseInt(n));
                subApLocal.numerosJogados = numJogados;
                const indexTipoAp = subApLocal.tipoEnum.split("-")[0];
                subApLocal.tipoEnum = TIPO_ENUM[indexTipoAp];
                return subApLocal;
            });
        } else subApostas = getMassaDeAposta();
        console.log("massa:", subApostas);
        let lGanhou = this.state.lGanhou;
        const cor = ["#FFD", "#FFD", "#FFD", "#FFD", "#FFD"];
        let totGanho = 0;
        let totAposta = 0;
        let divDetalhes = [];

        subApostas.forEach((subAposta, index) => {
            const { ganhouAqui, txGanhou, aAcertos } = Apura(
                this.state.nRandom,
                subAposta
            );
            totGanho += ganhouAqui;
            totAposta += subAposta.valorSubAposta;
            console.log(txGanhou);
            const divDetalhesSub = txGanhou.split("\n");
            //divDetalhes.shift()

            divDetalhes.push(<hr />);
            divDetalhes = divDetalhes.concat(divDetalhesSub);

            //se é grupo
            // cologcar aqui um loop para aAcertos <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
            aAcertos.forEach(acerto => {
                if (acerto.ganhou > 0) {
                    const indexTipoAp = TIPO_ENUM.indexOf(subAposta.tipoEnum);
                    const ehGrupo = L_MAX_N[indexTipoAp] === 25;
                    lGanhou[acerto.premio].push({
                                                    tipo: acerto.tipo,
                                                    resultado: acerto.resultado,
                                                    ganhou: acerto.ganhou
                                                });
                    cor[acerto.premio] = ehGrupo ? "#ff0" : "#FFC";
                }
                // console.log(txGanhou)
            });
        });

        const div = divDetalhes.map(linha => {
            return linha.toString().search(/ACERTOU|Aposta/) !== -1 ? (
                <p>
                    <strong>{linha}</strong>
                </p>
            ) : (
                <p>{linha}</p>
            );
        });

        
        this.props.setSaldo(this.state.saldo - totAposta + totGanho);
        this.setState({
                          cor: cor.map(cor =>
                                           cor === "#ff0" || cor === "#FFC" ? cor : "rgba(236,236,236,0.89)"
                          ),

                          paused: true,
                          saldo: this.state.saldo - totAposta + totGanho,
                          // showCount: null,
                          corSpin: "green",
                          mostraDetalhe: false,
                          displayButton: "block",
                          divDetalhes: div,
                          lGanhou,
                          totGanho
                      });
    };

    setNRandom = async e => {
        const nR = [0, 0, 0, 0, 0];

        for (let i = 0; i < 5; i++) {
            nR[i] = getRandomInt(0, 9999);
        }

        const cor = ["#FFD", "#FFD", "#FFD", "#FFD", "#FFD"];

        this.setState({
                          nRandom: nR,

                          cor,
                          mostraDetalhe: false,
                          displayButton: "none",

                          timeToCount: this.state.timeToCount + this.state.delta,
                          delta: -this.state.delta,
                          paused: false,
                          // showCount: <div> Aguarde ...</div>,
                          corSpin: "gray",
                          lGanhou: [[], [], [], [], []]
                      });
        await sleep(2000);
        this.apura();
    };

    handleAlteraApostaClick = (Ap, event) => {
        const repeteValue = {
            key: "aposta",
            aposta: this.props.aposta,
            from: "local"
        };
        this.props.onSelectRepete(repeteValue);
    };
    handlleMostraDetalhes = e => {
        this.setState({ displayButton: "none", mostraDetalhe: true });
    };
    render() {
        const { lGanhou, cor, nRandom, saldo } = this.state;
        return (
            <div>

                <div className={'cincoLinhas'} >
                    <CincoLinhasSorteadas

                        cor={cor}
                        nSorteado={nRandom}
                        lGanhou={lGanhou}
                    />

                    <div
                        style={{
                            padding:'0px 40px 0px 0px',
                            margin: "10px 20px",
                            display: "flex",
                            flexDirection: "column",

                            "justify-content": "space-between",
                            "align-items": "flex-end"
                        }}
                    >        <div
                        style={{

                            'border-style': 'dotted',
                            // color:'red',

                            margin: "0px 5px",
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center",
                            // "font-size": "small"
                        }}
                    >
                        {" "}
                        <span style={{ padding: "0px" , margin: "0px"}}>SALDO:  </span>{" "}
                        <span

                        >
            <Odometer
                auto={false}
                value={Math.floor(saldo)}
                duration={800}
                animation="count"
                theme="digital"
            />
          </span>

                    </div>
                        <OverlayTrigger
                            key="left"
                            placement="left-start"
                            // delay={{ show: 250, hide: 200 }}

                            overlay={
                                <strong style={{display:'flex', "flex-direction": "column", margin:'2px 5px', "align-items": "center", color: '#DC3545'
                                }}>SPIN! <FaSyncAlt/></strong>

                            }
                        >
                            <Button
                                className='spin'
                                type="submit"
                                variant="danger"
                                onClick={e => this.setNRandom(e)}
                                disabled={!this.state.paused}
                            >
                                <FaDice />{" "}
                                <img src={slotIcon} sizes={16} color={"white"} alt={"SLOT"} />
                                {/*SPIN <TiArrowRepeat/>*/}
                            </Button>
                        </OverlayTrigger>

                        <div
                            style={{
                                "flex-direction": "column",
                                display: "flex",
                                margin: "0px 10px 0px 10px",
                                // font: "small",
                                "align-items": "flex-start",
                                color:'black'
                            }}
                        >
                            <OverlayTrigger
                                key="repetir"
                                overlay={
                                    <Tooltip id={`tooltip`}>Editar/Incluir Aposta</Tooltip>
                                }
                            >
                                <Button
                                    variant="link"

                                    onClick={e => this.handleAlteraApostaClick(e)}
                                    disabled={!this.state.paused}
                                >
                                    {this.state.valorTotAposta ? (
                                        <p
                                            style={{
                                                margin: "0px 1px",
                                                // font: "small",
                                                color:'black'
                                            }}
                                        >
                                            Apostou: {this.state.valorTotAposta} <FaReply />
                                        </p>
                                    ) : (
                                        <div>
                                            <p style={{

                                                color:'red'
                                            }}>
                                                Ainda não apostou <FaReply /></p>

                                        </div>
                                    )}
                                </Button>
                            </OverlayTrigger>
                            {this.state.corSpin === "green" && (
                                <p
                                    style={{
                                        margin: "8px 1px",
                                        // font: "small"
                                    }}
                                >
                                    {" "}
                                    Ganhou: {this.state.totGanho}
                                </p>
                            )}

                        </div>

                        <Button
                            variant={"link"}
                            size="sm"
                            style={{ display: this.state.displayButton }}
                            onClick={e => this.handlleMostraDetalhes(e)}
                            disabled={!this.state.paused}
                        >
                            Detalhes <FaAngleDown />
                        </Button>


                    </div>


                </div>
                <div
                    style={{
                        margin: "30px 20px",
                        display: "flex",
                        "justify-content": "flex-start",
                        "align-items": "flex-start"
                    }}
                >
                    {this.state.mostraDetalhe && (
                        <div
                            style={{
                                margin: "0px 1px",
                                // font: "small"
                            }}
                        >
                            {this.state.divDetalhes}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
