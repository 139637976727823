import React, { useEffect, useState } from "react";
import Main from "../templates/Main"
import AppContext from '../../main/AppContext' //./AppContext'
import {FaSignInAlt,FaAngleDown,FaAngleUp } from "react-icons/fa"
import { Link } from 'react-router-dom'
import { TIPO_APOSTA, TIPO_PAGA, LottoBicho, } from '../../consts';
import TabelaBichos from './TabelaBichos'
import './Ajuda.css'
import { Button } from "react-bootstrap";




export default function DevForm({ onSubmit }) {
  const [mostra, setMostra] = useState({mHistorico:false, mSorteio:false, mAposta:false});
  const [gradiente, setGradiente] = useState({gHistorico:"gradiente", gSorteio:"gradiente", gAposta:"gradiente"});
  //const [techs, setTechs] = useState("");

 const handleHistorico= ()=> {
         const auxMostra = !mostra.mHistorico
         setMostra({...mostra, mHistorico: auxMostra})
         const auxGrad = auxMostra?"normal":"gradiente"
         setGradiente({...gradiente, gHistorico: auxGrad})
 }
 const handleSorteio= ()=> {
        const auxMostra = !mostra.mSorteio
        setMostra({...mostra, mSorteio: auxMostra})
        const auxGrad = auxMostra?"normal":"gradiente"
        setGradiente({...gradiente, gSorteio: auxGrad})
}
const handleAposta= ()=> {
        const auxMostra = !mostra.mAposta
        setMostra({...mostra, mAposta: auxMostra})
        const auxGrad = auxMostra?"normal":"gradiente"
        setGradiente({...gradiente, gAposta: auxGrad})
}
return(
        <AppContext.Consumer>
                {context => (



                        <Main icon="question-circle"
                                titleName={
                                        <strong className="text-danger">Como Jogar</strong>}
                                title=" !!"
                                subtitle='Veja abaixo as instruções do jogo'>
                                <p className="display-4">Instruções ...</p>
                                <br />
                               
                                        <strong>História</strong>
                               
                                <Button 
                                variant={"link"}
                                onClick={ handleHistorico}> 
                                
                                       {mostra.mHistorico?  <FaAngleUp />:<FaAngleDown />}
                                </Button>  
                                <p className={gradiente.gHistorico}>
                                        Assim como a capirinha, o samba e o Carnaval, o jogo do bicho é uma invenção brasileiríssima. Essa loteria foi criada em 1892 pelo barão João Batista Viana Drummond, fundador do Jardim Zoológico do Rio de Janeiro. 
                                        A intenção por trás da idéia era nobre: o barão queria atrair mais gente para o zôo, compensando o corte de verbas do governo, que mantinha o lugar. Para alimentar toda a fauna,
                                         Drummond mandou imprimir o desenho de 25 bichos nos ingressos. Pontualmente às 5 da tarde, sorteava um deles. Quem tivesse a figura vencedora ganhava 20 vezes o valor da entrada.

                                        
                                    {mostra.mHistorico &&
                                       <> “No início, todo visitante do zôo recebia um bilhete com a imagem de um bicho. Mas, a partir de 1894, cada um podia comprar quantos bilhetes quisesse. Daquele ano em diante, o jogo do bicho deixou de ser um simples sorteio e se transformou em um jogo de azar”, afirma a roteirista de cinema Elena Soárez, autora de uma tese de mestrado sobre o assunto. Para combater as apostas, que se tornaram uma mania em toda a cidade, a Prefeitura impediu o sorteio em 1895. Mas deu zebra: em vez de enfraquecer a jogatina, a proibição fortaleceu os bicheiros. Se antes eles compravam os ingressos no zôo e os revendiam pela cidade, a partir daquele momento eles se juntaram para realizar o sorteio por conta própria. Nem a ameaça de cadeia para os bicheiros com a criminalização do jogo, em 1946, conseguiu segurar a jogatina. Àquela altura, o bicho já era uma mania instalada no imaginário popular, apoiado em uma rede de relações pessoais e no infalível “jeitinho brasileiro” para driblar a repressão. O bicho pegou de vez na década de 80: com os lucros estratosféricos das apostas, os homens fortes do jogo se uniram com o crime organizado. Eles lançaram tentáculos em pelo menos seis áreas: tráfico de drogas e de armas, especulação imobiliária, prostituição, jogos eletrônicos e transporte clandestino, com peruas e lotações. Mesmo proibido, o bicho continua até hoje com três sorteios diários, representando uma pequena fatia da grana suja que alimenta os criminosos. “Com a proliferação das loterias oficiais, dos jogos eletrônicos e dos bingos, o bicho entrou em decadência e perdeu muito público”, afirma Elena.
                                        <a target="_blank" href="https://super.abril.com.br/mundo-estranho/qual-e-a-origem-do-jogo-do-bicho/">
                                                &nbsp; Mundo Estranho/SUPER Interessante - Ed Abril  </a>
                                        </>}

                                    
                                </p>

                            

                                
                                <p>No nosso {LottoBicho} a intençao é apenas lúdica. Nele você não aposta dinheiro de verdade apenas  <strong>SHITCOINS</strong>&nbsp;ou&nbsp;<strong>LOCALCOINS</strong>  .
                                                   No nosso aplicativo poderá desfrutar deste intreterimento de forma legal,
                         pois não há azar, só diversão</p>
                                
                         
                                <strong>Sorteios</strong> 
                                <Button 
                                variant={"link"}
                                onClick={ handleSorteio}> 
                                
                                       {mostra.mSorteio?  <FaAngleUp />:<FaAngleDown />}
                                </Button>  
                               <p className={gradiente.gSorteio}>
                                       
                                        
                                                Aqui que nosso {LottoBicho} se diferencia. Os sorteios para o jogo on-line são realizados de 3 em 3 minutos e o resultado 
                                                é apresentado na lateral esquerda.
                                                
                                                 O sorteio consiste em 5 números de 0 a 9999 (milhar), e é obitido por intermédio do site parceiro 

                                                <a target="_blank" href="https://www.random.org/" > RANDOM.ORG </a>
                                                Talvez você tenha se perguntado como máquinas previsíveis como computadores podem gerar aleatoriedade. 
                                                Na realidade, a maioria dos números aleatórios usados ​​em programas de computador é pseudo-aleatória , o que significa que eles são gerados de maneira previsível usando uma fórmula matemática. Isso é bom para muitos propósitos, mas pode não ser aleatório da maneira que você espera se estiver acostumado a cortar dados e sorteios.
                                      
                                 {mostra.mSorteio &&
                                       <>
                                       <br/>
                                        Talvez você tenha se perguntado como máquinas previsíveis como computadores podem gerar aleatoriedade. 
                                                Na realidade, a maioria dos números aleatórios usados ​​em programas de computador é pseudo-aleatória , o que significa que eles são gerados de maneira previsível usando uma fórmula matemática. Isso é bom para muitos propósitos, mas pode não ser aleatório da maneira que você espera se estiver acostumado a cortar dados e sorteios.
                                        <br/>
                                        O RANDOM.ORG oferece números aleatórios verdadeiros para qualquer pessoa na Internet.
                                                A aleatoriedade vem do ruído atmosférico, que para muitos propósitos é melhor do que os algoritmos de números pseudo-aleatórios normalmente usados ​​em programas de computador. 
                                        
                                
                                        <br />
                                        Outra opção de sorteio é pelo resultado da loteria da Caixa FEDERAL, 
                                        mas neste caso são realizados apenas dois sorteios por semana.
                                        <br/>
                                        Para apostar nos números gerados pelo RANDOM.ORG ou pela FEDERAL você utilizará os SHITCOINS e precisará estar "logado". 
                                                para tanto deverá se cadastrar com email e senha.&nbsp; 
                                        <Link to="/" className="logIn">
                                                <FaSignInAlt/> Entrar
                                        </Link>
                                        
                                        <br/>
                                                Para jogar loacalmente os números são gerados pelo computador e a moeda usada serão os LOCALCOINS.

                                        <br/>
                                        Neste caso não poderá obter a lista dos jogos feitos [Apostas/Lista] 
                                                nem consultar as compras e prêmios recebidos [Conta/Cota-Corrente]
                                              <br />
                                </>} 
                                </p>
                                

                                
                                
                             <strong>Apostas</strong>
                             <Button 
                                variant={"link"}
                                onClick={ handleAposta}> 
                                
                                       {mostra.mAposta?  <FaAngleUp />:<FaAngleDown />}
                                </Button> 
                               <p className={gradiente.gAposta}>
                                       
                                       O princípio básico do Jogo do Bicho é apostar em um animal que corresponde a um grupo de 4 números.
                                               Na tabela abaixo você pode ver todos os 25 animais que compõe o Jogo do Bicho,
                                cada um representando uma sequência de 4 números, e seguindo a ordem alfabética. 
                                        
                                                
                                 {mostra.mAposta &&
                                       <>
                                <br/>
                                <table className="tabela-bichos">

                                        <TabelaBichos />
                                </table>


                                <br />
                                <ul >

                                        <li>O Jogo do Bicho é um jogo tão único como democrático: não há uma aposta mínima, você pode apostar desde 1 até 1000 COINS, e em diferentes tipos de jogos.</li>
                                        <li>Seus ganhos serão proporcionais a quanto que apostou e ao estilo de aposta que você escolheu. </li>
                                        <li>O tipo de aposta mais comum é apostar 1,00 COIN em um animal representado pelos números de 1 a 25, conhecido como grupo.</li>
                                        <li>Cada tipo de aposta obedece proporções fixas, de acordo com a probabilidade de acerto, e que podem variar de cidade em cidade.
                                             Aqui no {LottoBicho} seguimos os tipos de apostas e as proporções de ganhos padrão. Por exemplo,
                                        apostando em um animal na cabeça (GRUPO) (1º. Prêmio) a proporção de ganho é de 1 para 18,
                                        ou seja, para cada COIN apostado, você ganharia 18x se seu animal saísse no sorteio.
                                        Abaixo seguem os outros tipos de aposta mais comuns (que explicaremos mais adiante ) e seus ganhos. Para cada COIN apostado, aqui se paga:
                                        </li> 
                                        <table className="tabela-bichos">
                                        {TIPO_APOSTA.map((ap,i)=> <tr>
                                                <td>{ap}</td> <td>... &nbsp;</td> <td>{TIPO_PAGA[i]}x</td>
                                                </tr>
                                        )}

                                        </table>

                                </ul>
                                </>}
                        </p>
                               
                        </Main>
                )}
        </AppContext.Consumer>)
}