
import axios from "axios";
import React, { Component } from "react";
import cogoToast from "cogo-toast";
import {FaArrowAltCircleRight} from "react-icons/fa"
import { Redirect} from 'react-router-dom'
import { API_URL } from "../../consts";

import "./ExtracaoBox.css";
import TelaExtracao from "../telaSorteio/TelaExtracao";

const initialState = {
  extracao: { serialNumber: 987654 }
};

const baseUrl = API_URL + "extracao/lista?size=1";
//const tempoEntreExtracoes = deltaExtracao()
//console.log(tempoEntreExtracoes)
export default class ExtracaoBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resposta: initialState.extracao,
      saida: <div>saida aqui</div>,
      aparece: "aparece",
      count: 0,
      dt: 0.1,
      timeToRepete: 1,
      ta: 0,
      tb: 0,
      foraDoAr:true,
    };
  }
  handleForaDoAr = ()=> this.setState({foraDoAr:true})
  render() {
    // let x = </div>
      const delta =
          Math.floor((this.state.ta - this.state.tb) / 1000) - this.state.count;
    return (


            <aside className="extracao">
                {this.state.foraDoAr?
                  <div className="pl-2">
                  <h3> Servidor fora do ar </h3> 
                  <p> Ou sem coneção com a internet </p>
                  <p>ainda poderá jogar localmente...</p>
                </div>
                :
                <TelaExtracao
                  random={this.state.resposta}
                  delta={delta}
                  timeToRepete={this.state.timeToRepete}
                />
                
              }
              
               
             
           </aside>



    );
  }

  componentWillMount() {
    let baseUrl2 = API_URL + "extracao/lista?size=2";

    cogoToast.loading(" ** Carregando a extração **").then(async () => {
     await axios.get(baseUrl2,{timeout: 5000}).then(resp => {
        let x = new Date(resp.data.data.content[0].completionTime).getTime();
        let y = new Date(resp.data.data.content[1].completionTime).getTime();
        const dLocale = new Date();
        const z = Math.floor(
          (dLocale.getTime() - x + dLocale.getTimezoneOffset() * 60000 - 7000) /
            1000
        )
        // @todo arrumar fuso horario . #dLocale.getTimezoneOffset()*60000 são 3 horas em milisegundos - 7 segundos de delay
        // @todo tem que ajustar o delay talvez atualizar o count com z em toda chamada de getRandom (tick)
        //console.log((x-y)/1000, z, "\n dLocale=",dLocale , dLocale.getTimezoneOffset()  )

        this.setState({
          ta: x,
          tb: y,
          count: z,
          timeToRepete: Math.floor((x - y) / 1000) - z,
          resposta: resp.data.data.content[0],
          foraDoAr:false
        });
       
        this.tick();
        cogoToast.info("Pronto!!!", { hideAfter: 1 });
      })/*.catch(function (error) {
        console.log(`erro extracao 1 ${error}`);
      
        const { hide } = cogoToast.error( <div>

          <h2> Servidor fora do ar </h2> 
          <p> Ou sem coneção com a internet </p>
          <p>ainda poderá jogar localmente...</p>
         
        </div>
        ,{
           hideAfter: 10,
        })
        
      });*/
    });
  }

  tick() {
    this.setState({ count: this.state.count + 1 });
    const delta =
      Math.floor((this.state.ta - this.state.tb) / 1000) - this.state.count;

    if (delta <= 0) {
      this.setState({
        count: 0,
        timeToRepete: (this.state.ta - this.state.tb) / 1000 + this.state.dt
      });

      //console.log("xxxxxxxxxacesso ajax aqui a cada (ta-tb -z) segundos xxxxxxxxxxxxxxxx=")
      axios(baseUrl,{timeout: 2000}).then(resp => {

          const { serialNumber, completionTime } = resp.data.data.content[0];
          this.props.setExtracaoRandom(serialNumber)
          this.props.setDataRandom(completionTime)
          this.props.setDeltaRandom(this.state.ta - this.state.tb)
        this.setState({
          resposta: resp.data.data.content[0],
          dt: -this.state.dt,
          foraDoAr:false
          //@todo atualizar count com z
        })
        }).catch(this.handleForaDoAr);
    }
  }

  componentDidMount() {
   // const interval = 
    setInterval(() => this.tick(), 1000);
  }
}

