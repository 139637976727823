import React from "react";
import bicho1 from "./assets/bichosImg/bicho1.png";
import bicho2 from "./assets/bichosImg/bicho2.png";
import bicho3 from "./assets/bichosImg/bicho3.png";
import bicho4 from "./assets/bichosImg/bicho4.png";
import bicho5 from "./assets/bichosImg/bicho5.png";
import bicho6 from "./assets/bichosImg/bicho6.png";
import bicho7 from "./assets/bichosImg/bicho7.png";
import bicho8 from "./assets/bichosImg/bicho8.png";
import bicho9 from "./assets/bichosImg/bicho9.png";
import bicho10 from "./assets/bichosImg/bicho10.png";
import bicho11 from "./assets/bichosImg/bicho11.png";
import bicho12 from "./assets/bichosImg/bicho12.png";
import bicho13 from "./assets/bichosImg/bicho13.png";
import bicho14 from "./assets/bichosImg/bicho14.png";
import bicho15 from "./assets/bichosImg/bicho15.png";
import bicho16 from "./assets/bichosImg/bicho16.png";
import bicho17 from "./assets/bichosImg/bicho17.png";
import bicho18 from "./assets/bichosImg/bicho18.png";
import bicho19 from "./assets/bichosImg/bicho19.png";
import bicho20 from "./assets/bichosImg/bicho20.png";
import bicho21 from "./assets/bichosImg/bicho21.png";
import bicho22 from "./assets/bichosImg/bicho22.png";
import bicho23 from "./assets/bichosImg/bicho23.png";
import bicho24 from "./assets/bichosImg/bicho24.png";
import bicho25 from "./assets/bichosImg/bicho25.png";

export const IMG_BICHO = [
  bicho1,
  bicho2,
  bicho3,
  bicho4,
  bicho5,
  bicho6,
  bicho7,
  bicho8,
  bicho9,
  bicho10,
  bicho11,
  bicho12,
  bicho13,
  bicho14,
  bicho15,
  bicho16,
  bicho17,
  bicho18,
  bicho19,
  bicho20,
  bicho21,
  bicho22,
  bicho23,
  bicho24,
  bicho25
];

export const NOME_BICHOS = [
  "Avestruz ",
  "Águia    ",
  "Burro    ",
  "Borboleta",
  "Cachorro ",
  "Cabra    ",
  "Carneiro ",
  "Camelo   ",
  "Cobra    ",
  "Coelho   ",
  "Cavalo   ",
  "Elefante ",
  "Galo     ",
  "Gato     ",
  "Jacaré   ",
  "Leão     ",
  "Macaco   ",
  "Porco    ",
  "Pavão    ",
  "Peru     ",
  "Touro    ",
  "Tigre    ",
  "Urso     ",
  "Veado    ",
  "Vaca     "
];
 
export const L_MAX_N = [
  25,
  25,
  9999,
  9999,
  999,
  999,
  99,
  99,
  25,
  99,
  25,
  99,
  25,
  25
];
export const L_MIN_N = [1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 1, 1];
export const TIPO_PAGA = [
  18.0,
  3.6,
  4000.0,
  800.0,
  600.0,
  120.0,
  60.0,
  12.0,
  19.0,
  300.0,
  130.0,
  3000.0,
  75.0,
  37.5
];

export const TIPO_ENUM = [
  "GRUPO",
  "GRUPO_5",
  "MILHAR",
  "MILHAR_5",
  "CENTENA",
  "CENTENA_5",
  "DEZENA",
  "DEZENA_5",
  "DUQUE",
  "DUQUE_D",
  "TERNO",
  "TERNO_D",
  "PASSE_VAIVEM",
  "PASSE_VAI"
];

export const TIPO_APOSTA = [
  "0-Grupo                   ",
  "1-Grupo 1/5               ",
  "2-Milhar                  ",
  "3-Milhar 1/5              ",
  "4-Centena                 ",
  "5-Centena 1/5             ",
  "6-Dezena                  ",
  "7-Dezena 1/5              ",
  "8-Dupla/Duque de grupo 1/5",
  "9-Duque de dezena 1/5     ",
  "10-Terno de grupo 1/5     ",
  "11-Terno de dezena 1/5    ",
  "12-Passe Vai-vem (PVV)    ",
  "13-Passe Vai (PV)         "
];

export const QT_PREMIOS = idx => {
  return [1, 3, 5, 7, 8, 9, 10, 11, 12, 13].includes(idx) ? 5 : 1;
}; // undefined, not found }

export const CERCADO=[1,5,1,5,1,5,1,5,5,5,5,5,5,5]
export const getBicho = n => {
  const nBicho = Math.floor((n - Math.floor(n / 100) * 100) / 4 + 0.75);
  return nBicho === 0 ? 25 : nBicho;
};
export const toZeros = (n, digits) => {
  const dig = n < 10 && digits === undefined ? 2 : digits;
  const z = "0".repeat(dig);
  const str = z + n.toString();
  return str.substr(str.length - dig);
};

export const NumerosJogados = props => {
  const indexTipoAp = TIPO_APOSTA.indexOf(props.tipoAposta);
  const ehGrupo = L_MAX_N[indexTipoAp] === 25;
  const Qt_Zeros = L_MAX_N[indexTipoAp] + 1;
  const digits = Qt_Zeros.toString().length - 1;
  return props.numeros.map(n => (
    <div>
      {n !== null && n !== "-" ? (
        ehGrupo ? (
          <span>
            {" "}
            <BichoIco nBicho={n} cor={props.cor || "#FFF"} />{" "}
          </span>
        ) : (
          <span> {toZeros(n, digits)} </span>
        )
      ) : null}
    </div>
  ));
};
export const BichoIco = props => (
  <img
    src={IMG_BICHO[props.nBicho - 1]}
    width={props.tamanho || 50}
    height={((props.tamanho || 50) * 8) / 9}
    style={{ "background-color": props.cor, margin: "1px" }}
    alt="BichoImg 40x45"
  />
);

export const W_STORAGE_TOKEN_KEY = "_LB_TOKEN";

// export const OAPI_URL = "https://192.168.0.28:8888/api/";
//  export const API_URL = "http://localhost:8888/api/";
//   export const OAPI_URL = "http://localhost:8888/api/";
export const  API_URL= process.env.REACT_APP_RUN==="LOCAL" ?"https://192.168.0.112:443/api/"
                       :process.env.REACT_APP_RUN==="NGINX"  ? "https://192.168.0.112:8888/api/"
                       :process.env.REACT_APP_RUN==="NETFLY"? 'https://jb.lottobicho.com:9090/api/'
                       : process.env.NODE_ENV==='production'? 'https://mysrv.meucloud.xyz:8888/api/'
                       
                       :"https://192.168.0.112:8888/api/"

export const OAPI_URL=  process.env.NODE_ENV==='production'
                    ?'https://jb.lottobicho.com:8888/api/'
                    :"https://192.168.0.112:8888/api/";

export const LOCALE = "pt-BR"

export const LottoBicho =  <span className="text-primary">
Lo<strong  className="text-danger">tt</strong>oBicho
  </span>

  export const VERSAO = process.env.NODE_ENV+" @ 20200211 22:08-03"