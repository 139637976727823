import React from "react";
import ComponenteBicho from "./ComponenteBicho";
import Odometer from "react-odometerjs";
import { toZeros, getBicho } from "../../consts";
import "../../lib/odometer-theme-digital.css";
import "../../lib/odometer-theme-slot-machine.css";

const $_Style = bollean => (
  <strong
    style={{
      "font-family": "Rye",
      padding: "0.15em",
      color: "#f80000",
      "line-height": "1.35em",
      "-webkit-text-stroke": "0.05em #000"
    }}
  >
    {bollean ? "$" : ""}
  </strong>
);

export const LinhaSorteada = props => (
  <div
    style={{
      margin: "5px 10px 2px 10px",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "font-size": " large",
        height: "100px"
    }}
  >
    <ComponenteBicho nBicho={getBicho(props.nSorteado) - 1} cor={props.cor} />

    <div style={{ margin: "5px 10px 20px 10px" }}>
      <Odometer
        value={props.nSorteado + props.nOrd * 10000}
        format="(º  #dddd).dddd"
      />
      {$_Style(props.lGanhou.length > 0)}
      {props.lGanhou.length > 0 &&
        props.lGanhou.map(jogo => (
          <div
            style={{
              "font-size": "small",
              color: "red"
            }}
          >
            {jogo.tipo} [<strong>{toZeros(jogo.resultado)}</strong>] ${" "}
            {jogo.ganhou}
          </div>
        ))}
    </div>
  </div>
);

LinhaSorteada.defaultProps = {
  nSorteado: 1,
  nOrd: 1,
  cor: "#FFD",
  lGanhou: []
};
