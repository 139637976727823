import React from 'react'
import Main from "../templates/Main"

import AppContext from '../../main/AppContext' //./AppContext'
import {FaDice} from "react-icons/fa"
import { Card, Tab, Tabs } from 'react-bootstrap';
import CrudAposta from "./CrudAposta"
import ListaApostas from "./ListaApostas"
import ExtracaoLocal from "./ExtracaoLocal"
import ApostaHome from "./ApostaHome"
import {VERSAO} from "../../consts"

export default class ApostaMain extends React.Component {
  constructor(props, context) {
super(props, context);
    this.state = {
      key: props.key === undefined ? 'home' : props.key,
      Ap: null,
      from : "Main"
    }


  this.handleRepete = (repeteValue) => {
      this.setState({key: repeteValue.key, Ap: repeteValue.aposta, from: repeteValue.from});
      console.log("###### repeteValue.aposta", repeteValue.aposta)
  }
   

  // Use the regular Map constructor to transform a 2D key-value Array into a map
   this.titleMap = new Map([
    ['home', 'Aposte com prudência'],
   ['aposta', 'Escolha em que apostar'],
    ['listaAposta', 'Aqui a lista de suas apostas'] ,
    ['local','Você pode jogar off-line aqui']]);
  }
  

    render() {
      // let x = </div>
      return (
        <AppContext.Consumer>
          {context => (
            <Main
              icon="money"
              titleName={<span className=" m-2">Façam suas Apostas...</span>}
              title={<FaDice />}
              subtitle="Hora de ganhar com seus palpites!!!."
            >
              <Card>
                <Card.Header>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.key}
                    onSelect={key => this.setState({ key })}
                  >
                    <Tab eventKey="home" title="Home"/>
                    <Tab eventKey="aposta" title="Aposta"/>
                    <Tab eventKey="listaAposta" title="Lista"/>
                    <Tab eventKey="local" title="Local"/>
                  </Tabs>
                </Card.Header>
                <Card.Body>
                    { this.state.key !=='local' && <Card.Title className="text-primary">
                    {" "}
                    {this.titleMap.get(this.state.key)}
                  </Card.Title>}
                  <Card.Text>
                    {this.state.key === "aposta" && (
                      <div>
                        <CrudAposta
                          from={this.state.from}
                          aposta={this.state.Ap}
                          onSelectRepete={this.handleRepete}
                        />
                      </div>
                    )}
                    {this.state.key === "home" && (
                      <div>
                        <ApostaHome onSelectRepete={this.handleRepete} />
                      </div>
                    )}
                    {this.state.key === "listaAposta" && (
                      <div>
                        <ListaApostas onSelectRepete={this.handleRepete} />
                      </div>
                    )}
                    {this.state.key === "local" && (
                      <div>
                        <ExtracaoLocal
                          saldo={context.localSaldo}
                          setSaldo={context.setLocalSaldo}
                          aposta={
                            this.state.from === "aposta" ? this.state.Ap : null
                          }
                          onSelectRepete={this.handleRepete}
                        />
                      </div>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>{" "}
              ... v.{VERSAO}
              {context.userOj.nome === "Logar" && true
              // <Auth/>
              }
            </Main>
          )}
        </AppContext.Consumer>
      );}}