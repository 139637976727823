import { Button } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import React from "react";
import "./ModalSubAp.css";

import {  NumerosJogados } from "../../consts";
import TelaExtracao from "../telaSorteio/TelaExtracao";


export default class ModalSubAp extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: props.show
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  getJsonRequest = () => {
    const jsonInicio = {
      method: "generateSignedIntegers",
      hashedApiKey:
        "tv5q5JwpFPGAhvQfN4jq3y+T64YNcYqLs6kDDzQsbFeVZsYnIOWVW+q14vRWMBm8DAZlWeoi5OOQSjO3G5psHg==",
      n: 5,
      min: 0,
      max: 9999,
      replacement: true,
      base: 10
    };
    const jsonData = { data: this.props.respExtracao.data };


    const jsonMeio = {
      license: {
        type: "developer",
        text:
          "Random values licensed strictly for development and testing only",
        infoUrl: null
      },
      userData: null
    };
    const { completionTime, serialNumber, origem } = this.props.respExtracao;
    const jsonFim = { completionTime: completionTime + "Z", serialNumber };
    const jsonRequest = { ...jsonInicio, ...jsonData, ...jsonMeio, ...jsonFim };

    return JSON.stringify(jsonRequest);
  };
  render() {
    const nj = this.props.subAp.numerosJogados;

    const jsonRequest = this.getJsonRequest();
   
    const ehRandomOrg = this.props.respExtracao.origem==="RANDOMORG"
    //console.log("jsonRequest:\n", jsonRequest);
    return (
      <Modal show={this.state.show} onHide={this.handleClose} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Detalhes da aposta {this.props.apostaNumero}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-primary">Detallhes do soteio. </h5>

          <TelaExtracao random={this.props.respExtracao} />

          <hr />

          <h5 className="text-primary">
            Detalhes da aposta de indice #{this.props.subAp.index}.{" "}
          </h5>
          <p>Jogou no {this.props.subAp.tipoEnum}</p>
          <p>
            numeros jogados = {nj[0]} {nj[1] ? " | " + nj[1] : ""}{" "}
            {nj[2] ? " | " + nj[2] : ""}{" "}
          </p>
          <p>
            {" "}
            <NumerosJogados
              numeros={nj}
              tipoAposta={this.props.subAp.tipoEnum}
              cor={"#FFD"}
            />{" "}
          </p>

          <p>Apostou =${this.props.subAp.valorSubAposta}</p>
          <p className="text-danger">
            {this.props.ganhou ? `Ganhou:$ ${this.props.ganhou}` : "Nao Ganhou"}
          </p>
          <p className="assinatura">
            Assinatura para conferencia:
            <br/> "{ehRandomOrg? this.props.respExtracao.assinatura: 'Extração da FEDERAL é de conhecimento público'}"
          </p>

          <form
            action=  {ehRandomOrg
                                  ?"https://api.random.org/signatures/form"
                                  :'http://loterias.caixa.gov.br/wps/portal/loterias/landing/federal/!ut/p/a1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOLNDH0MPAzcDbz8vTxNDRy9_Y2NQ13CDA0MzIAKIoEKnN0dPUzMfQwMDEwsjAw8XZw8XMwtfQ0MPM2I02-AAzgaENIfrh-FqsQ9wBmoxN_FydLAGAgNTKEK8DkRrACPGwpyQyMMMj0VAYe29yM!/dl5/d5/L2dBISEvZ0FBIS9nQSEh/pw/Z7_HGK818G0KGAB50QMU0UQ6S10G0/res/id=historicoHTML/c=cacheLevelPage/=/' }
            target="_blank"
            method="post"
          >
            <input type="hidden" name="format" value="json" />
            <input type="hidden" name="random" value={jsonRequest} />
            <input
              type="hidden"
              name="signature"
              value={this.props.respExtracao.assinatura}
            />
            <input
              className="btn btn-outline-secondary"
              type="submit"
              value="Validar"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}