import './Nav.css'
import React from 'react'

import {Link} from 'react-router-dom'
export default props =>
    <aside className="menu">
        <nav className="menu">
        {/* Refatorar em casa! para transformar em um componet */}
            <Link to="/">
                <i className="fa fa-home"></i> Início
            </Link>
            <Link to="/apostas">
                <i className="fa fa-money"></i> Apostas
            </Link>
            <Link to="/users">
                <i className="sm-display-none fa fa-users"></i> Conta
            </Link>
            <Link to="/ajuda">

                <i className="sm-display-none fa fa-question-circle"></i> Ajuda
            </Link>
        </nav>
    </aside>
