/* 
https://github.com/pughpugh/react-countdown-clock#props
prop	type	default	description
seconds	integer	60	Seconds to countdown
color	string	#000	Colour of counter
alpha	float	1.0	Alpha transparency of counter
size	integer	300	Width & height of canvas element
weight	integer		Weight of circle, in pixels
fontSize	integer/string	auto	px size of font. auto for dynamic sizing.
font	string	Arial	Font of counter
timeFormat	string	seconds	Counter style; seconds or hms
showMilliseconds	boolean	true	Show milliseconds for last 10 seconds
onComplete	func		Callback when time completes
paused	boolean	false	Pause countdown of the timer
pausedText	string		Text to display when paused, defaults to the current time

*/



import React, { Component } from 'react'
import ReactCountdownClock from "react-countdown-clock" // npm i react-countdown-clock --save

   /*  var _TEMPO_COUNTDOWN_PADRAO_ = 3*60+7; //não sei re-renderizar depois trocar por props
    var _T_O_G_L_E_ = false
    
    var toggleTimeByOne = ()=>{
      _T_O_G_L_E_=!_T_O_G_L_E_
      return _T_O_G_L_E_?_TEMPO_COUNTDOWN_PADRAO_+0.1:_TEMPO_COUNTDOWN_PADRAO_-0.1
    }
 */
    
    export default class ModeloCountdown extends Component {

      constructor(props) {
        super(props);
        
        this.state = {
          // seconds: toggleTimeByOne(),// necessário para reenderizar : trocar depois de aprender re-renderizar
          color: '#6265f8',
          fontSize: 'auto',
          weight: 15,
          size:80
        }
      
    }
      
     
     /*  
     // não utilizei aqui
     handleOnComplete= ()=>{
        this.setState( {
          seconds: toggleTimeByOne(),
        paused: false,
        fontSize: 'auto',
       } )
      }
 */
     
      render(){
        return (
         
          <ReactCountdownClock
            seconds= {this.props.timeToRepete}  
            color= {this.props.cor}
            alpha= {0.8}
            // onComplete= { this.handleOnComplete}
            fontSize= {this.state.fontSize}
            weight={this.state.weight}
            size={this.state.size}
            timeFormat={"hms"}/>
          )}        
      }
   

    